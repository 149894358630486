import * as React from 'react';
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchWalletBalanceDataApi, generateWalletApi, walletWithdrawalRequestApi} from "../../api/walletsService";
import {selectUser, setUser} from "../../redux/users";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from '@mui/material/CircularProgress';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import {useTranslation} from 'react-i18next';
import useNotification from '../../useNotification';
import {useAuth0} from "@auth0/auth0-react";
import ProgressLoader from "../_components/ProgressLoader";
import {networkOptions} from "../../constants/networkOptions";
import IconText from "../_components/IconText";
import {FormControl, InputLabel, Select} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {ContentCopy} from "@mui/icons-material";
import {currencyOptions} from "../../constants/currencyOptions";
import {Platforms} from "../../types";
import Title from "../dashboard/Title";

export default function Wallets() {
    const menuItemIconSize = '1.6rem';
    const {t} = useTranslation();
    const {getAccessTokenSilently} = useAuth0();
    const {notify} = useNotification();
    const [activeAddress, setActiveAddress] = useState<string | null>(null);
    const [activeNetwork, setActiveNetwork] = useState<string | null>(null);
    const [activeCurrency, setActiveCurrency] = useState<string | null>(null);
    const [balance, setBalance] = useState<string | null>(null);
    const [fee, setFee] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [externalCryptoAddress, setExternalCryptoAddress] = useState<string>('');
    const [amount, setAmount] = useState<string | null>(null);
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const checkWalletBalance = async (network: string, currency: string) => {
        const token = await getAccessTokenSilently();
        if (token == null) {
            notify(t('authFailed'), "warning");
        } else {
            setLoading(true);
            fetchWalletBalanceDataApi(token, network, currency)
                .then((response) => {
                    setBalance(response.data.amount);
                    setFee(response.data.fee.toString());
                    notify("Balance updated", "info")
                })
                .catch((error) => {
                    notify(error, "error")
                })
                .finally(() => setLoading(false));
        }
    }

    const handleGenerateWallet = async (network: string) => {
        const token = await getAccessTokenSilently();
        if (token == null) {
            notify(t('authFailed'), "warning");
        } else {
            setLoading(true);
            generateWalletApi(token, network)
                .then((response) => {
                    dispatch(setUser(response.data));
                    notify("Wallet created", "success");
                })
                .catch((error) => {
                    notify(error, "error");
                })
                .finally(() => setLoading(false));
        }
    }

    const handleCopy = async (text: string) => {
        try {
            await navigator.clipboard.writeText(text);
            notify(t('copied'), 'success');
        } catch (err) {
            notify(t('copyFailed'), 'error');
        }
    }

    const handleWithdrawal = async () => {
        if (amount == null) {
            notify(t('fillAmount'), 'warning');
            return;
        }
        const amountNumber = parseFloat(amount);
        if (isNaN(amountNumber) || amountNumber <= 0) {
            notify(t('invalidAmount'), 'warning');
            return;
        }
        if (externalCryptoAddress == null || externalCryptoAddress.length <= 0) {
            notify(t('invalidBloggerAddress'), 'error');
            return;
        }
        if (activeNetwork == null || activeCurrency == null) {
            notify(t('stopCheating'), 'error');
            return;
        }
        setLoading(true);
        const token = await getAccessTokenSilently();

        walletWithdrawalRequestApi(token, activeNetwork, activeCurrency, externalCryptoAddress, amountNumber)
            .then((response) => {
                setBalance(response.data.amount)
                notify("Balance updated", "info")
            })
            .catch((error) => {
                notify(error, "error")
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        setBalance(null);
        setActiveAddress(null);
        if (!!activeNetwork && !!activeCurrency) {
            const network = networkOptions.find(item => item.value === activeNetwork);
            if (network !== undefined) {
                switch (network.platform) {
                    case Platforms.ETHEREUM: {
                        setActiveAddress(user.ethCryptoAddress);
                        return;
                    }
                    case Platforms.TRON: {
                        setActiveAddress(user.trxCryptoAddress);
                        return;
                    }
                    default:
                        throw new Error('Unsupported platform' + network.platform);
                }
            }
        }
    }, [activeNetwork, activeCurrency, user]);

    if (user == null) {
        console.log(user)
        return (
            <ProgressLoader/>
        );
    }

    return (
        <Container maxWidth="md" sx={{px:'0px!important'}}>
            <Box mb={4}>
                <Card>
                    <CardContent>
                        <Title>
                            {t("walletsManagement")}
                        </Title>
                        <Grid container spacing={2} pt={1} alignItems="flex-end">
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel id={"network-select-label"}>{t("network")}</InputLabel>
                                    <Select
                                        labelId={"network-select-label"}
                                        label={t("network")}
                                        value={activeNetwork}
                                        margin={'dense'}
                                        onChange={(event) => setActiveNetwork(event.target.value)}
                                        fullWidth
                                    >
                                        {
                                            networkOptions.map(nO => {
                                                return (
                                                    <MenuItem key={'network-' + nO.value} value={nO.value}>
                                                        <IconText text={t(nO.text)} alt={nO.alt}
                                                                  src={nO.src}
                                                                  iconSize={menuItemIconSize}/>
                                                    </MenuItem>
                                                );
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel id={"currency-select-label"}>{t("currency")}</InputLabel>
                                    <Select
                                        labelId={"currency-select-label"}
                                        label={t("currency")}
                                        value={activeCurrency}
                                        margin={'dense'}
                                        onChange={(event) => setActiveCurrency(event.target.value)}
                                        fullWidth
                                    >
                                        {
                                            currencyOptions.map(nO => {
                                                return (
                                                    nO.supportedNetwork.includes(activeNetwork ?? '') &&
                                                    <MenuItem key={'currency-' + nO.value} value={nO.value}>
                                                            <IconText text={t(nO.text)} alt={nO.alt}
                                                                      src={nO.src}
                                                                      iconSize={menuItemIconSize}/>
                                                    </MenuItem>
                                                );
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            {!!activeAddress && (
                                <>
                                    <Grid item alignSelf={'center'} xs={12}>
                                        <FormControl fullWidth margin={'dense'}>
                                            <TextField
                                                aria-readonly={true}
                                                value={activeAddress}
                                                label={t("walletAddress")}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton onClick={() => handleCopy(activeAddress ?? "")}
                                                                    type="button">
                                                            <ContentCopy/>
                                                        </IconButton>
                                                    )
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item alignSelf={'center'} xs={12}>
                                        <FormControl fullWidth margin={'dense'}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => activeNetwork && activeCurrency && checkWalletBalance(activeNetwork, activeCurrency)}
                                                disabled={loading}
                                                fullWidth
                                                size="large"
                                            >
                                                {loading ? <CircularProgress size={24}/> : t("checkBalance")}
                                            </Button>
                                        </FormControl>
                                    </Grid>
                                </>
                            )}
                            {!!activeNetwork && !!activeCurrency && (activeAddress == null || activeAddress.length <= 0) &&
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleGenerateWallet(activeNetwork)}
                                        disabled={loading}
                                        fullWidth
                                        size="large"
                                    >
                                        {loading ? <CircularProgress size={24}/> : t("generateWallet")}
                                    </Button>
                                </Grid>
                            }
                            {balance !== null &&
                                <>
                                    <Grid item xs={12}>
                                        <Divider style={{margin: '20px 0'}}/>
                                        <FormControl fullWidth margin={'dense'}>
                                            <Typography>{t("walletBalance")}</Typography>
                                        </FormControl>
                                        <FormControl fullWidth sx={{textAlign: 'end'}}>
                                            <Typography>{balance + ' ' + activeCurrency}</Typography>
                                        </FormControl>
                                        <FormControl fullWidth margin={'dense'}>
                                            <Typography>{t("fee")}</Typography>
                                        </FormControl>
                                        <FormControl fullWidth sx={{textAlign: 'end'}}>
                                            <Typography>{fee + ' ' + activeCurrency}</Typography>
                                        </FormControl>
                                    </Grid>
                                    {user.isBlogger &&
                                        <Grid item xs={12}>
                                            <FormControl fullWidth margin="dense">
                                                <TextField
                                                    label={t("amount")}
                                                    type="text"
                                                    value={amount}
                                                    onChange={e => setAmount(e.target.value)}
                                                    inputProps={{
                                                        pattern: "^\d*(\.\d{0,2})?$",
                                                        inputMode: "decimal",
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl fullWidth margin="dense">
                                                <TextField
                                                    label={t("walletAddress")}
                                                    type="text"
                                                    value={externalCryptoAddress}
                                                    onChange={e => setExternalCryptoAddress(e.target.value)}
                                                />
                                            </FormControl>
                                            <FormControl fullWidth margin="dense">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleWithdrawal}
                                                    size="large"
                                                    disabled={loading}
                                                >
                                                    {loading ? <CircularProgress size={24}/> : t("withdraw")}
                                                </Button>
                                            </FormControl>
                                        </Grid>
                                    }
                                </>
                            }
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </Container>
    );
}
