import React, {useState, useEffect} from 'react';
import {CssBaseline, Box, Divider, ThemeProvider, useTheme, useMediaQuery, Theme} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';
import {useDispatch} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import LandingPageTopBar from '../components/landing/LandingPageTopBar';
import Hero from '../components/landing/Hero';
import Features from '../components/landing/Features';
import FAQ from '../components/landing/FAQ';
import Footer from '../components/landing/Footer';
import SupportedTokens from '../components/landing/SupportedTokens';
import walletTheme from '../utils/CustomTheme';
import Highlights from "../components/landing/Highlights";
import Typography from "@mui/material/Typography";
import AgeVerificationModal from "../components/landing/AgeVerificationModal";

export default function LandingPage() {
    const navigate = useNavigate();
    const {isAuthenticated, error} = useAuth0();
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const [isOfAge, setIsOfAge] = useState<boolean | null>(null);

    useEffect(() => {
        if (error != null) {
            console.error(error);
        }
    }, [error]);

    useEffect(() => {
        if (navigate && isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    useEffect(() => {
        const promo = searchParams.get('promo');
        if (!!promo?.length) {
            sessionStorage.setItem('promo', promo);
        }
    }, [dispatch, searchParams]);

    const handleAgeVerification = (ageConfirmed: boolean) => {
        setIsOfAge(ageConfirmed);
    };

    if (isOfAge === false) {
        return (
            <ThemeProvider theme={walletTheme}>
                <CssBaseline/>
                <Box sx={{textAlign: 'center', mt: 20}}>
                    <Typography variant="h4" color="error">You must be 18 years or older to use this site.</Typography>
                </Box>
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={walletTheme}>
            <CssBaseline/>
            {isOfAge === null && <AgeVerificationModal onConfirm={handleAgeVerification}/>}
            {isOfAge && (
                <>
                    <LandingPageTopBar/>
                    <Hero/>
                    <Box sx={{bgcolor: 'background.default', padding: isMobile ? 2 : 4}}>
                        <Divider sx={{my: isMobile ? 2 : 4}}/>
                        <Features/>
                        <Divider sx={{my: isMobile ? 2 : 4}}/>
                        <SupportedTokens/>
                        <Divider sx={{my: isMobile ? 2 : 4}}/>
                        <Highlights/>
                        <Divider sx={{my: isMobile ? 2 : 4}}/>
                        <FAQ/>
                        <Divider sx={{my: isMobile ? 2 : 4}}/>
                        <Footer/>
                    </Box>
                </>
            )}
        </ThemeProvider>
    );
}
