import * as React from 'react';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import {useState} from "react";
import {useSelector} from "react-redux";
import {KycStatus} from "../../types";
import Container from "@mui/material/Container";
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import '../../i18n';
import {selectUser} from "../../redux/users";
import {mapKycStatus} from "../../utils/transformations";
import {selectCards} from "../../redux/cards";
import CreditCard from "./_components/CreditCard";
import CardPlaceholder from "./_components/CardPlaceholder";
import {Skeleton} from "@mui/material";

export default function Cards() {
    const [loading, setLoading] = useState<boolean>(false);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const cards = useSelector(selectCards);

    if (mapKycStatus(user?.kyc) !== KycStatus.ADOPT_PASS) {
        return (
            <Container maxWidth="md">
                <Box mt={2}>
                    <Alert severity="warning">
                        {t("completeKyc")}
                    </Alert>
                </Box>
                <Box mt={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate('/settings')}
                    >
                        {t("startKyc")}
                    </Button>
                </Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="lg"  sx={{px:'0px!important'}}>
            <Box mt={4} sx={{xs: {px: '0px!important'}}}>
                <Typography variant="h4" gutterBottom>
                    {t("cardsManagement")}
                </Typography>
                {loading ? (
                    <Box sx={{borderRadius: '15px', overflow: 'hidden', maxWidth: '374px', height: '220px'}}>
                        <Skeleton animation={'wave'} width={'inherit'} height={'inherit'}
                                  variant={'rectangular'}></Skeleton>
                    </Box>
                ) : (
                    <Grid container>
                        {
                            cards.length > 0 ?
                                (
                                    cards.map((card, index) => (
                                        <Grid item xs={12} key={index}>
                                            <CreditCard card={card}/>
                                        </Grid>
                                    ))
                                )
                                : (
                                    <Grid item xs={12}>
                                        <CardPlaceholder setLoading={setLoading}/>
                                    </Grid>
                                )
                        }
                    </Grid>
                )}
            </Box>
        </Container>
    );
}
