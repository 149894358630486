import React, {useState, useEffect} from 'react';
import {Modal, Box, Typography, Button, Checkbox, FormControlLabel} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import {useTranslation} from "react-i18next";

const AgeVerificationModal: React.FC<{ onConfirm: (isOfAge: boolean) => void }> = ({onConfirm}) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(!localStorage.getItem('ageVerified'));
    const [isOfAge, setIsOfAge] = useState(false);
    const [notFromRestrictedCountries, setNotFromRestrictedCountries] = useState(false);
    const theme = useTheme();

    const handleConfirm = () => {
        setOpen(false);
        localStorage.setItem('ageVerified', 'true');
        onConfirm(true);
    };

    useEffect(() => {
        if (localStorage.getItem('ageVerified')) {
            onConfirm(true);
        }
    }, [onConfirm]);

    return (
        <Modal
            open={open}
            aria-labelledby="age-verification-modal-title"
            aria-describedby="age-verification-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    bgcolor: 'background.paper',
                    border: `2px solid ${theme.palette.primary.main}`,
                    boxShadow: 24,
                    p: 4,
                    textAlign: 'center',
                    borderRadius: 2
                }}
            >
                <Typography
                    id="age-verification-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{color: theme.palette.primary.main}}
                >
                    {t("disclaimer.header")}
                </Typography>
                <Typography
                    id="age-verification-modal-description"
                    sx={{mt: 2, mb: 2}}
                >{t("disclaimer.citizenshipDisclaimer")}
                </Typography>
                <Alert severity="warning" sx={{mb: 2}}>
                    {t("disclaimer.ageRequirement")}
                </Alert>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={notFromRestrictedCountries}
                            onChange={(e) => setNotFromRestrictedCountries(e.target.checked)}
                            color="primary"
                        />
                    }
                    label={t("disclaimer.notFromListedCountries")}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isOfAge}
                            onChange={(e) => setIsOfAge(e.target.checked)}
                            color="primary"
                        />
                    }
                    label={t("disclaimer.eighteenOrOlder")}
                />
                <Box sx={{display: 'flex', justifyContent: 'center', gap: 2}}>
                    <Button
                        onClick={handleConfirm}
                        variant="contained"
                        color="primary"
                        disabled={!isOfAge || !notFromRestrictedCountries}
                        sx={{
                            minWidth: '80px',
                            ':hover': {
                                bgcolor: theme.palette.primary.dark,
                            }
                        }}
                    >
                        {t("confirm")}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default AgeVerificationModal;
