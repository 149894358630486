import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import SaveButton from "./_components/SaveButton";
import Section from "./_components/Section";
import * as React from "react";

function SecurityBlock() {
    return (<Section container spacing={2}>
        <Grid item xs={12}>
            <FormControlLabel
                control={<Switch/>}
                label="Two-Factor Authentication"
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                label="Backup Email"
                fullWidth
                variant="outlined"
            />
        </Grid>
        <Grid item xs={12}>
            <SaveButton variant="contained" color="primary">
                Save Security Settings
            </SaveButton>
        </Grid>
    </Section>)
}

export default SecurityBlock;
