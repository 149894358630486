export interface User {
    email: string;
    phoneNumber: string;
    nickname: string;
    kyc: string;
    ethCryptoAddress: string;
    trxCryptoAddress: string;
    sourceReferralId: string;
    isBlogger: boolean;
}

export interface ReferralData {
    id: string;
    promoCode: string;
    discount: number;
}

export interface Balance {
    address: string;
    network: string;
    amount: string;
    fee: number;
}

export interface CreditCardPreview {
    id: string;
    cardLevel: string;
    cardNoLast4: string;
    applyStatus: string;
    status: string;
}

export interface CreditCardDetails {
    cardId: string;
    phoneNumber: string;
    phoneAreaCode: string;
    cardLevel: string;
    cardNetwork: string;
    cardType: string;
    currency: string;
    cardNoLast4: string;
    cardStatus: string;
    embossedName: string;
    expiryMonth: string;
    expiryYear: string;
    issueTime: string;
    cardAvailableBalance: number;
    dailyPurchaseLimit: number;
}

export interface KycDetailsForm {
    firstName: string;
    lastName: string;
    dateOfBirth: string; // "yyyy-MM-dd"
    occupation: string;
    annualIncome: string;
    documentType: string;
    documentNumber: string;
    documentCountry: string;
    documentExpiryDate: string; // "yyyy-MM-dd"
    addressDetails: string;
    addressCity: string;
    addressCountry: string; // ISO3166-1 alpha-2 format
    addressPostCode: string;
    nickName: string;
    contactPhone: string;
}

export interface KycDetailsRequest {
    firstName: string;
    lastName: string;
    dateOfBirth: number; // timestamp
    occupation: string;
    annualIncome: string;
    documentType: string;
    documentNumber: string;
    documentCountry: string;
    documentExpiryDate: number;  // timestamp
    addressDetails: string;
    addressCity: string;
    addressCountry: string;
    addressPostCode: string;
    nickName: string;
    contactPhone: string;
}

export enum KycStatus {
    NOT_SET = "0",
    WAIT_AUDIT = "1",
    IN_AUDIT = "2",
    ADOPT_PASS = "3",
    REFUSE = "4",
    UNDEFINED = "UNDEFINED"
}

export enum CardStatus {
    INIT = "INIT",
    PENDING_ISSUE = "PENDING_ISSUE",
    PENDING_ACTIVE = "PENDING_ACTIVE",
    ACTIVE = "ACTIVE",
    LOCK = "LOCK",
    SUSPENDED = "SUSPENDED",
    CANCELED = "CANCELED",
    UNDEFINED = "UNDEFINED"
}

export enum CardApplyStatus {
    APPLYING = "APPLYING",
    WAIT_AUDIT = "WAIT_AUDIT",
    AUDIT_PASS = "AUDIT_PASS",
    CARD_INIT = "CARD_INIT",
    AUDIT_REFUSE = "AUDIT_REFUSE",
    UNDEFINED = "UNDEFINED"
}

export enum CardType {
    PHYSICAL = "PHYSICAL",
    VIRTUAL = "VIRTUAL"
}

export enum CardLevel {
    PLATINUM = "1",
    CLASSIC_1 = "2",
    CLASSIC_2 = "3",
    UNDEFINED = "UNDEFINED"
}

export interface TopUpResponse {
    amount: string;
    currency: string | null;
    fee: string;
    status: string;
}

export interface CardTransaction extends TopUpResponse {
    createdAt: number;
    postedAt: number;
    type: string
    paymentCurrency: string
}

export interface CryptoTransaction {
    cardId: string,
    creationTimestamp: number,
    amount: number,
    network: string,
    currency: string,
    type: string,
}

export interface CardSensitiveInfo {
    url: string;
}

export interface SensitiveResponse {
    code: string;
    data: CardSensitiveResponse;
    message: string;
}

export interface SensitiveUrlResponse {
    code: string;
    data: CardSensitiveUrlResponse;
    message: string;
}

// do not remove!
// export interface CardSensitiveResponse {
//     card_id: string;
//     card_number: string;
//     expire_month: string;
//     expire_year: string;
//     security_code: string;
// }

export interface CardSensitiveResponse {
    cardId: string;
    cardNumber: string;
    expireMonth: string;
    expireYear: string;
    securityCode: string;
}

export interface CardSensitiveUrlResponse {
    url: string;
}

export enum CardTransactionType {
    TOP_UP = 'TOP_UP',
    CHARGE = 'CHARGE',
    FEE = 'FEE',
    REFUND = 'REFUND'
}

export enum CryptoTransactionType {
    TOP_UP = 'TOP_UP',
    CARD_PURCHASE = 'CARD_PURCHASE',
    INCOMING_TRANSFER = 'INCOMING_TRANSFER',
    OUTGOING_TRANSFER = 'OUTGOING_TRANSFER'
}

export enum Platforms {
    ETHEREUM = "ETHEREUM",
    TRON = "TRON",
}

export enum CryptoNetworks {
    TRC20 = "TRC20",
    ERC20 = "ERC20",
    ARBITRUM = "ARBITRUM",
    BSC = "BSC"
}

export enum CryptoCurrency {
    USDT = 'USDT',
    USDC = 'USDC',
}

export interface CardPrice {
    price: String;
    discount: String;
}
