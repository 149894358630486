import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import useNotification from "../../useNotification";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/users";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import {
    fetchReferralDataApi,
    fetchReferralSourceApi,
    uploadReferralPromoCodeApi
} from "../../api/referralService";
import axios from "axios";
import {ReferralData} from "../../types";
import IconButton from "@mui/material/IconButton";
import {ContentCopy} from "@mui/icons-material";
import ProgressLoader from "../_components/ProgressLoader";
import Title from "../dashboard/Title";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import {FormControl} from "@mui/material";

export default function Referral() {
    const {t} = useTranslation();
    const {getAccessTokenSilently} = useAuth0();
    const {notify} = useNotification();
    const user = useSelector(selectUser);
    const [referralSource, setReferralSource] = useState<ReferralData | null>(null);
    const [referralSourceLoading, setReferralSourceLoading] = useState<boolean>(true);
    const [referralCode, setReferralCode] = useState<ReferralData | null>(null);
    const [referralCodeLoading, setReferralCodeLoading] = useState<boolean>(true);
    const [promoCode, setPromoCode] = useState<string>("");

    const retrieveUserReferralSource = async () => {
        const token = await getAccessTokenSilently();
        if (token == null) {
            notify(t('authFailed'), "warning");
            return;
        }
        fetchReferralSourceApi(token)
            .then(response => {
                setReferralSource(response.data);
            })
            .catch(
                e => console.error(e)
            )
            .finally(
                () => setReferralSourceLoading(false)
            );
    }

    const retrieveUserReferralData = async () => {
        const token = await getAccessTokenSilently();
        if (token == null) {
            notify(t('authFailed'), "warning");
            return;
        }
        fetchReferralDataApi(token)
            .then(response => {
                setReferralCode(response.data);
            })
            .catch(
                e => console.error(e)
            )
            .finally(
                () => setReferralCodeLoading(false)
            );
    }

    const handleUsePromoCode = async () => {
        if (!promoCode) {
            notify(t("pleaseFillInPromoCode"), "warning");
            return;
        }
        setReferralSourceLoading(true);
        try {
            const token = await getAccessTokenSilently();
            if (token == null) {
                notify(t('authFailed'), "warning");
            } else {
                await uploadReferralPromoCodeApi(token, promoCode);
                await retrieveUserReferralSource();
            }
        } catch (err) {
            if (axios.isAxiosError(err)) {
                notify(t(err.response?.data.toString()), "error");
            }
        } finally {
            setReferralSourceLoading(false);
        }
    };

    const handleCopy = async (text: string) => {
        try {
            await navigator.clipboard.writeText(text);
            notify(t('copied'), 'success');
        } catch (err) {
            notify(t('copyFailed'), 'error');
        }
    }

    const getReferralUrl = () => {
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('promo', promoCode);
        const newUrl = currentUrl.origin + '/welcome?promo=' + referralCode?.id;

        return newUrl.toString();
    }

    useEffect(() => {
        if (user) {
            retrieveUserReferralData().then();
            retrieveUserReferralSource().then();
        }
    }, []);

    if (user == null) {
        return (<ProgressLoader/>);
    }

    return (
        (referralCodeLoading || referralSourceLoading) ? <ProgressLoader/>
            : (
                <Container maxWidth="md" sx={{px:'0px!important'}}>
                    <Box mb={4}>
                        <Card>
                            <CardContent>
                                <Title>
                                    {t("referralManagement")}
                                </Title>
                                {referralSource !== null && referralSource?.promoCode !== null && referralSource.promoCode.length > 0 ? (
                                    <>
                                        <TextField
                                            aria-readonly={true}
                                            value={referralSource?.promoCode}
                                            label={t("usedPromoCode")}
                                            margin="dense"
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleCopy(referralSource?.promoCode ?? "")}
                                                                type="button">
                                                        <ContentCopy/>
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </>
                                ) : referralSource !== null && referralSource?.id !== null && referralSource.id.length > 0 ? <></> : (
                                    <>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={12}>
                                                <TextField
                                                    autoFocus
                                                    margin="dense"
                                                    label={t("promoCode")}
                                                    fullWidth
                                                    onChange={(e) => setPromoCode(e.target.value)}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12} display="flex" justifyContent="flex-end">
                                                <Button
                                                    onClick={handleUsePromoCode}
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    sx={{
                                                        width: {xs: '100%', sm: '60%', md: '40%'},
                                                    }}
                                                >
                                                    {t("usePromoCode")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </CardContent>
                            {
                                referralCode !== null ?
                                    <>
                                        <Divider/>
                                        <CardContent>
                                            {referralCode?.promoCode &&
                                                <TextField
                                                    aria-readonly={true}
                                                    value={referralCode?.promoCode.toUpperCase()}
                                                    label={t("generatedPromoCode")}
                                                    fullWidth
                                                    margin={'dense'}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <IconButton
                                                                onClick={() => handleCopy(referralCode?.promoCode.toUpperCase() ?? "")}
                                                                type="button">
                                                                <ContentCopy/>
                                                            </IconButton>
                                                        )
                                                    }}
                                                />
                                            }
                                            {
                                                referralCode.id &&
                                                <TextField
                                                    aria-readonly={true}
                                                    value={getReferralUrl()}
                                                    label={t("generatedPromoLink")}
                                                    fullWidth
                                                    margin={'dense'}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <IconButton onClick={() => handleCopy(getReferralUrl())}
                                                                        type="button">
                                                                <ContentCopy/>
                                                            </IconButton>
                                                        )
                                                    }}
                                                />
                                            }
                                            <FormControl fullWidth sx={{paddingLeft:1, paddingTop:2}}>
                                                <Typography fontWeight={'bold'}>{t('discount') + ' $'}{Number(referralCode.discount).toFixed(2)}</Typography>
                                            </FormControl>
                                        </CardContent>
                                    </>
                                    :
                                    <>
                                        <Box sx={{p: 2, borderRadius: 1}}>
                                            <Alert severity="warning">
                                                {t("promoRequirements")}
                                            </Alert>
                                        </Box>
                                    </>
                            }
                        </Card>
                    </Box>
                </Container>
            )
    );
}
