import {CryptoNetworks, Platforms} from "../types";

interface NetworkOption {
    value: string;
    text: string;
    alt: string;
    src: string;
    platform: string;
}

const networkOptions:NetworkOption[] = [
    {
        value: CryptoNetworks.ERC20,
        text: 'Ethereum (ERC-20)',
        alt: 'ETH',
        src: require('../assets/images/eth.png'),
        platform: Platforms.ETHEREUM
    },
    {
        value: CryptoNetworks.BSC,
        text: 'Binance Smart Chain (BEP-20)',
        alt: 'BNB',
        src: require('../assets/images/bnb.png'),
        platform: Platforms.ETHEREUM
    },
    {
        value: CryptoNetworks.ARBITRUM,
        text: 'Arbitrum',
        alt: 'ARB',
        src: require('../assets/images/arb.png'),
        platform: Platforms.ETHEREUM
    },
    // {
    //     value: CryptoNetworks.TRC20,
    //     text: 'TRON (TRC20) [POORLY_SUPPORTED]',
    //     alt: 'TRX',
    //     src: require('../assets/images/trc.png'),
    //     platform: Platforms.TRON
    // },
];

export {networkOptions}
