import {useSnackbar} from 'notistack';

const useNotification = () => {
    const {enqueueSnackbar} = useSnackbar();

    const notify = (message: string, variant: 'default' | 'error' | 'success' | 'warning' | 'info' = 'default') => {
        enqueueSnackbar(message, {variant});
    };

    return {notify};
};

export default useNotification;
