import {useAuth0} from "@auth0/auth0-react";
import useNotification from "../../useNotification";
import React, {useEffect, useState} from "react";
import {CardStatus, CardTransaction, CardType, CreditCardDetails} from "../../types";
import {
    changeCardPhoneNumberApi, changeCardPinApi,
    fetchCardDataApi,
    fetchCardTransactionsApi,
    lockCardApi,
    unlockCardApi
} from "../../api/cardsService";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {selectCardByLastDigits, setCards} from "../../redux/cards";
import cardStyle from "../../styles/creditCardStyle";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import CardDetailInfo from "./_components/CardDetailInfo";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import TopUpDialog from "./_components/TopUpDialog";
import {mapCardApplyStatus, mapCardLevel, mapCardStatus} from "../../utils/transformations";
import {ContactPhone, FiberPin, Lock, LockOpen, Settings} from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import {Autocomplete, Menu, MenuItem} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import ProgressLoader from "../_components/ProgressLoader";
import IconText from "../_components/IconText";
import TextField from "@mui/material/TextField";
import {countriesData} from "../../constants/countries/countriesData";
import axios from "axios";

function CardDetails() {
    const {getAccessTokenSilently} = useAuth0();
    const {notify} = useNotification();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {last4digits} = useParams();
    const cardPreview = useSelector(selectCardByLastDigits(last4digits));
    const dispatch = useDispatch();
    const [details, setDetails] = useState<CreditCardDetails | null>(null);
    const [topUpOpen, setTopUpOpen] = useState<boolean>(false);
    const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
    const [showTransaction, setShowTransaction] = useState<boolean>(false);
    const [transactions, setTransactions] = useState<CardTransaction[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const [openCardLockDialog, setOpenCardLockDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dialogContent, setDialogContent] = useState("");
    const [phoneChangeDialogOpen, setPhoneChangeDialogOpen] = useState(false);
    const [countryCode, setCountryCode] = useState<string>("");
    const [phoneCode, setPhoneCode] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [pinCode, setPinCode] = useState<string>("");
    const [pinCodeConfirmation, setPinCodeConfirmation] = useState<string>("");
    const [pinChangeDialogOpen, setPinChangeDialogOpen] = useState(false);
    const [pinCodeError, setPinCodeError] = useState('');
    const numRegex = /^[0-9]*$/;
    const pinRegex = /^[0-9]{4,12}$/;

    const handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSettingsClose = () => {
        setAnchorEl(null);
    };

    const handleClickOpenCardLockDialog = () => {
        if (cardPreview?.id === '') return;
        const cardStatus = mapCardStatus(cardPreview?.status);
        if (cardStatus === CardStatus.ACTIVE) {
            setDialogContent(t("lockCard"));
            setOpenCardLockDialog(true);
        } else if (cardStatus === CardStatus.LOCK) {
            setDialogContent(t("unlockCard"));
            setOpenCardLockDialog(true);
        }
    };

    const handleCardLockDialogClose = async (confirm: boolean) => {
        setOpenCardLockDialog(false);
        if (confirm) {
            try {
                setLoading(true);
                const token = await getAccessTokenSilently();
                if (token == null) {
                    notify(t('authFailed'), "warning");
                } else if (cardPreview !== undefined) {
                    const cardStatus = mapCardStatus(cardPreview.status);
                    let promise;
                    if (cardStatus === CardStatus.ACTIVE) {
                        promise = lockCardApi(token, cardPreview.id);
                        promise.then(() => {
                            notify(t('locked'), 'success');
                        });
                        promise.catch(() => {
                            notify(t('failedToLockCard'), "error")
                        });
                    } else if (cardStatus === CardStatus.LOCK) {
                        promise = unlockCardApi(token, cardPreview.id);
                        promise.then(() => {
                            notify(t('unlocked'), 'success');
                        });
                        promise.catch(() => {
                            notify(t('failedToUnlockCard'), "error")
                        });
                    } else {
                        promise = Promise.reject();
                    }
                    promise.then((response) => {
                        dispatch(setCards(response.data));
                    });
                    promise.finally(() => setLoading(false));
                }
            } catch (err) {
                notify(t("unexpectedError"), "error");
            }
        }
    };

    const loadDetails = async (cardId: string) => {
        try {
            const token = await getAccessTokenSilently();
            if (token == null) {
                notify(t('authFailed'), "warning");
            } else {
                fetchCardDataApi(token, cardId)
                    .then((response) => {
                        setDetails(response.data);
                    })
                    .catch(() => {
                        notify(t("failedToFetchCardDetails"), "error");
                        setDetails(null);
                    });
            }
        } catch (err) {
            notify(t("failedToFetchCardDetails"), "error");
        }
    }

    const handleTopUpClick = () => {
        setTopUpOpen(true);
    };

    const handleTopUpClose = () => {
        setTopUpOpen(false);
    };

    const handleToggleMoreInfo = () => {
        setShowMoreInfo(!showMoreInfo);
    };

    const uploadTransactions = async () => {
        const token = await getAccessTokenSilently();
        if (cardPreview == null || cardPreview.id == null) {
            notify(t('authFailed'), "warning");
            return
        }
        if (token == null) {
            notify(t('authFailed'), "warning");
        } else {
            fetchCardTransactionsApi(token, cardPreview.id)
                .then((response) => setTransactions(response.data))
        }
    }

    const handleToggleShowTransactions = () => {
        if (!showTransaction && transactions.length === 0) {
            uploadTransactions().then(() => setShowTransaction(true))
        } else {
            setShowTransaction(!showTransaction);
        }
    };

    const handleBackClick = () => {
        navigate("/cards");
    };

    function composePhone() {
        if (details == null || details.phoneNumber == null) {
            return "-";
        }
        return `+(${details.phoneAreaCode})-${details.phoneNumber}`;
    }

    function composeNetwork() {
        if (details == null) {
            return "-";
        }
        return t(details.cardNetwork);
    }

    function composeType() {
        if (details == null) {
            return "-";
        }
        return t(details.cardType);
    }

    function composeStatus() {
        if (details == null) {
            return "-";
        }
        return t(details.cardStatus);
    }

    const styles = cardStyle(mapCardStatus(cardPreview?.status), mapCardApplyStatus(cardPreview?.applyStatus), mapCardLevel(cardPreview?.cardLevel), false);

    const handleClickOpenPhoneChangeDialog = () => {
        setPhoneChangeDialogOpen(true);
    };

    const handleClickOpenPinChangeDialog = () => {
        setPinChangeDialogOpen(true);
        clearPinCode();
    };

    const handleClosePhoneChangeDialog = () => {
        setPhoneChangeDialogOpen(false);
    };

    const handleClosePinChangeDialog = () => {
        setPinChangeDialogOpen(false);
        clearPinCode();
    };

    const clearPinCode = () => {
        setPinCode('');
        setPinCodeConfirmation('');
    }

    const sortedCountriesByPhoneCode = countriesData.sort((a, b) => a.phoneCode - b.phoneCode);

    const handleChangePhone = async () => {
        if (!phoneCode || !phoneNumber) {
            notify(t("pleaseFillInPhone"), "warning");
            return;
        }
        setLoading(true);
        try {
            const token = await getAccessTokenSilently();
            if (token == null) {
                notify(t('authFailed'), "warning");
            } else if (cardPreview !== undefined) {
                const response = await changeCardPhoneNumberApi(token, cardPreview.id, phoneCode, phoneNumber)
                dispatch(setCards(response.data));
                notify(t('updated'), "success");
                handleClosePhoneChangeDialog();
            }
        } catch (err) {
            if (axios.isAxiosError(err)) {
                notify(t(err.response?.data.toString()), "error");
            }
        } finally {
            setLoading(false);
        }
    };

    const handleChangePin = async () => {
        if (pinCode !== pinCodeConfirmation) {
            notify(t("pinCodesDontMatch"), "warning");
            return;
        }
        if (!pinRegex.test(pinCode)) {
            setPinCodeError(t('pinCodeLength'));
        } else {
            setPinCodeError('');
        }
        if (pinCode === '') {
            return;
        }
        setLoading(true);
        try {
            const token = await getAccessTokenSilently();
            if (token == null) {
                notify(t('authFailed'), "warning");
            } else if (cardPreview !== undefined) {
                const response = await changeCardPinApi(token, cardPreview.id, pinCode);
                notify(t(response.data), "success");
                handleClosePinChangeDialog();
            }
        } catch (err) {
            if (axios.isAxiosError(err)) {
                notify(t(err.response?.data.toString()), "error");
            }
        } finally {
            setLoading(false);
        }
    };

    const handlePinInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;

        if (numRegex.test(value) || value === '') {
            setPinCode(value.toString());
        }
    };

    const handlePinConfirmationInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;

        if (numRegex.test(value) || value === '') {
            setPinCodeConfirmation(value.toString());
        }
    };

    useEffect(() => {
        if (cardPreview != null && cardPreview.id != null) {
            loadDetails(cardPreview.id);
        }
    }, [cardPreview, cardPreview?.id]);

    if (loading) {
        return <ProgressLoader></ProgressLoader>
    }

    return (
        <Box sx={{width: '100%'}}>
            <AppBar position="static" color="default" sx={{boxShadow: 'none', color: 'secondary.contrastText'}}>
                <Toolbar sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                    <Stack direction={'row'} alignItems={'center'}>
                        <IconButton edge="start" color="inherit" onClick={handleBackClick}>
                            <ArrowBackIcon/>
                        </IconButton>
                        <Typography variant="h6" component="div">
                            {t("cardDetails")}
                        </Typography>
                    </Stack>
                    <IconButton onClick={handleSettingsClick}>
                        <Settings/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Card sx={{...styles, maxWidth: '100%', width: '374px', margin: '20px auto', position: "relative"}}>
                <CardDetailInfo cardNoLast4={cardPreview?.cardNoLast4} details={details}/>
            </Card>
            <Grid container spacing={{md: 2}} sx={{maxWidth: '100%', margin: '20px auto'}}>
                <Grid item xs={12} md={12}>
                    <Button variant="contained" color="primary" fullWidth onClick={handleTopUpClick}
                            sx={{margin: "10px 0"}}>
                        {t("topUpCard")}
                    </Button>
                    <TopUpDialog open={topUpOpen} onClose={handleTopUpClose} cardId={cardPreview?.id}
                                 cardNoLast4={cardPreview?.cardNoLast4}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button variant="text" color="secondary" fullWidth onClick={handleToggleMoreInfo}
                            sx={{margin: "10px 0"}}>
                        {showMoreInfo ? t("hideMoreInfo") : t("showMoreInfo")}
                    </Button>

                    <Collapse in={showMoreInfo}>
                        <Box sx={{backgroundColor: "#f5f5f5", borderRadius: 1, margin: '20px 0'}}>
                            <Typography variant="body2"><strong>{t("phone")}: </strong>{composePhone()}</Typography>
                            <Divider sx={{my: 1}}/>
                            <Typography variant="body2"><strong>{t("cardNetwork")}: </strong>{composeNetwork()}
                            </Typography>
                            <Divider sx={{my: 1}}/>
                            <Typography variant="body2"><strong>{t("cardType")}: </strong>{composeType()}</Typography>
                            <Divider sx={{my: 1}}/>
                            <Typography variant="body2"><strong>{t("cardStatus")}: </strong>{composeStatus()}
                            </Typography>
                        </Box>
                    </Collapse>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button variant="text" color="secondary" fullWidth onClick={handleToggleShowTransactions}
                            sx={{margin: "10px 0"}}>
                        {showTransaction ? t("hideTransactions") : t("showTransactions")}
                    </Button>

                    <Collapse in={showTransaction}>
                        <TableContainer component={Paper} sx={{marginTop: 2, px: 2, py: 1}}>
                            <Table aria-label="transactions table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{padding: 1}}>{t("date")}</TableCell>
                                        <TableCell sx={{padding: 1}} align="center">{t("orderType")}</TableCell>
                                        <TableCell sx={{padding: 1}} align="center">{t("orderAmount")}</TableCell>
                                        <TableCell sx={{padding: 1}} align="center">{t("orderStatus")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {transactions.map((transaction) => {
                                        return (
                                            <TableRow>
                                                <TableCell
                                                    sx={{padding: 1}}>{new Date(transaction.postedAt).toLocaleString()}</TableCell>
                                                <TableCell
                                                    sx={{padding: 1}}>{t(transaction.type.toLowerCase())}</TableCell>
                                                <TableCell
                                                    sx={{padding: 1}}>{transaction.amount} {transaction.currency}</TableCell>
                                                <TableCell
                                                    sx={{padding: 1}}>{t(transaction.status.toString())}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Collapse>
                </Grid>
            </Grid>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={menuOpen}
                onClose={handleSettingsClose}
                onClick={handleSettingsClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                {details !== null && details.cardType === CardType.PHYSICAL &&
                    <MenuItem onClick={handleClickOpenPinChangeDialog}>
                        <ListItemIcon>
                            <FiberPin fontSize="small"/>
                        </ListItemIcon>
                        {t('changePin')}
                    </MenuItem>
                }
                <MenuItem onClick={handleClickOpenPhoneChangeDialog}>
                    <ListItemIcon>
                        <ContactPhone fontSize="small"/>
                    </ListItemIcon>
                    {t('updatePhone')}
                </MenuItem>
                {cardPreview?.status === CardStatus.ACTIVE &&
                    <MenuItem onClick={handleClickOpenCardLockDialog}>
                        <ListItemIcon>
                            <Lock fontSize="small"/>
                        </ListItemIcon>
                        {t('lockCard')}
                    </MenuItem>
                }
                {cardPreview?.status === CardStatus.LOCK &&
                    <MenuItem onClick={handleClickOpenCardLockDialog}>
                        <ListItemIcon>
                            <LockOpen fontSize="small"/>
                        </ListItemIcon>
                        {t('unlockCard')}
                    </MenuItem>
                }

            </Menu>
            <Dialog
                open={openCardLockDialog}
                onClose={() => handleCardLockDialogClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t("confirmation")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCardLockDialogClose(false)} color="primary">
                        {t("cancel")}
                    </Button>
                    <Button onClick={() => handleCardLockDialogClose(true)} color="primary" autoFocus>
                        {t("confirm")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={phoneChangeDialogOpen} onClose={handleClosePhoneChangeDialog}>
                <DialogTitle>{t("updatePhone")}</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        options={sortedCountriesByPhoneCode}
                        getOptionLabel={(option) => `+${option.phoneCode.toString()} (${option.abbreviation})`}
                        renderOption={(props, option) => (
                            <MenuItem key={option.phoneCode.toString() + option.abbreviation}  {...props}>
                                <IconText
                                    text={`+${option.phoneCode.toString()} (${option.abbreviation})`}
                                    alt={option.abbreviation.toLowerCase()}
                                    src={option.flagBase64}
                                    iconSize={"24px"}
                                />
                            </MenuItem>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("phoneCode")}
                                name={'phoneCode'}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                            />
                        )}
                        value={sortedCountriesByPhoneCode.find(c => c.phoneCode.toString() === phoneCode
                            && c.abbreviation.toLowerCase() === countryCode) || null}
                        onChange={(_, newValue) => {
                            setPhoneCode(newValue?.phoneCode.toString() ?? "")
                            setCountryCode(newValue?.abbreviation.toLowerCase() ?? "")
                        }}
                    />
                    <TextField
                        margin="dense"
                        label={t("phoneNumber")}
                        fullWidth
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        variant="outlined"
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePhoneChangeDialog} color="primary">
                        {t("cancel")}
                    </Button>
                    <Button onClick={handleChangePhone} color="primary">
                        {t("confirm")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={pinChangeDialogOpen} onClose={handleClosePinChangeDialog}>
                <DialogTitle>{t("changePin")}</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label={t("newPinCode")}
                        fullWidth
                        value={pinCode}
                        type={'password'}
                        onChange={handlePinInputChange}
                        variant="outlined"
                        error={pinCodeError.length > 0}
                        helperText={pinCodeError}
                        required
                    />
                    <TextField
                        margin="dense"
                        label={t("newPinCodeConfirmation")}
                        fullWidth
                        value={pinCodeConfirmation}
                        type={'password'}
                        onChange={handlePinConfirmationInputChange}
                        variant="outlined"
                        required
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePinChangeDialog} color="primary">
                        {t("cancel")}
                    </Button>
                    <Button onClick={handleChangePin} color="primary">
                        {t("confirm")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default CardDetails;
