import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import CardContent from "@mui/material/CardContent";
import React, {useState} from "react";
import {CardSensitiveResponse, CreditCardDetails} from "../../../types";
import {useTranslation} from "react-i18next";
import {fetchCardSensitiveUrlApi} from "../../../api/cardsService";
import {useAuth0} from "@auth0/auth0-react";
import useNotification from "../../../useNotification";

function CardDetailInfo({cardNoLast4, details}: {
    cardNoLast4: string | undefined,
    details: CreditCardDetails | null
}) {
    const {t} = useTranslation();
    const {notify} = useNotification();
    const {getAccessTokenSilently} = useAuth0();
    const [showDetails, setShowDetails] = useState(false);
    const [sensitiveData, setSensitiveData] = useState<CardSensitiveResponse | null>(null);

    const handleToggleDetails = async () => {
        setShowDetails(prevState => !prevState);
    };

    const onSensitiveDataClick = async () => {
        const token = await getAccessTokenSilently();
        if (details == null || details.cardId == null) {
            return
        }
        if (token == null) {
            notify(t('authFailed'), "warning");
        } else {
            const response = await fetchCardSensitiveUrlApi(token, details.cardId)

            if (response.status === 200) {
                console.log(response);
                setSensitiveData(response.data);
                setShowDetails(true);
            } else {
                setShowDetails(false);
            }

            // // TODO implement server-side flow where all sensitive data will be retrieved by server
            // const urlResponse = await fetchCardSensitiveUrlApi(token, details.cardId)
            // if (urlResponse.status === 200 && urlResponse?.data?.url?.length > 0) {
            //     let isLocal = window.location.href.includes('localhost');
            //     let url = urlResponse.data.url;
            //     if (isLocal) {
            //         url = url.replace('https://api.musepay.io', 'https://lucrumwallet.net/musapay');
            //     }
            //     axios.create().get<SensitiveResponse>(url, {headers: {Authorization: `Bearer ${token}`}})
            //         .then((response) => {
            //             console.log(response)
            //             setSensitiveData(response.data.data)
            //             setShowDetails(true)
            //         })
            //         .catch(() => setShowDetails(false))
            // }
        }
    }

    function composeExpirationData() {
        if (details == null) {
            return "**/****";
        }
        return `${details.expiryMonth}/${details.expiryYear}`;
    }

    function composeCardNumber() {
        if (sensitiveData == null || !showDetails) {
            return `**** **** **** ${details?.cardNoLast4 || cardNoLast4}`;
        }
        return sensitiveData.cardNumber.replace(/(\d{4})(?=\d)/g, '$1 ');

    }

    function composeSecurityCode() {
        if (sensitiveData == null || !showDetails) {
            return "CVV2";
        }
        return sensitiveData.securityCode;
    }

    function composeCardCurrency() {
        if (details == null) {
            return "";
        }
        return details?.currency;
    }

    function composeCardHolder() {
        if (details == null) {
            return "****";
        }
        return details.embossedName;
    }

    function composeAvailableBalance() {
        if (details == null) {
            return "-";
        }
        return details.cardAvailableBalance;
    }

    function composeCardNetwork() {
        if (details == null) {
            return "";
        }
        return details.cardNetwork;
    }

    function composeDailyLimit() {
        if (details == null) {
            return "-";
        }
        return details.dailyPurchaseLimit;
    }

    const sensitiveDataStyle = {
        cursor: "pointer",
        filter: !showDetails || sensitiveData == null ? "blur(1px)" : "none",
        border: !showDetails || sensitiveData == null ? '1px solid white' : '',
        padding: '2px 5px',
        borderRadius: '3px'
    }

    return (
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body2" gutterBottom
                                style={{color: 'white', position: "absolute", top: "15px", left: "15px"}}>
                        {composeCardNetwork()}
                    </Typography>
                </Grid>
                <Grid item xs={12} onClick={onSensitiveDataClick}>
                    <Typography variant="h5" gutterBottom
                                style={{
                                    color: 'white',
                                    position: "absolute",
                                    top: "55px",
                                    left: "25px",
                                    whiteSpace: 'nowrap'
                                }}
                                onMouseEnter={(e) => (e.currentTarget.style.cursor = "pointer")}
                    >
                        {composeCardNumber()}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" gutterBottom
                                style={{color: 'white', position: "absolute", bottom: "95px", left: "25px"}}>
                        {t("expires", {value: composeExpirationData()})}
                    </Typography>
                </Grid>
                <Grid item xs={6} onClick={onSensitiveDataClick}>
                    <Typography variant="body1" gutterBottom
                                style={{
                                    color: 'white',
                                    position: "absolute",
                                    bottom: "84px",
                                    right: "25px",
                                    ...sensitiveDataStyle
                                }}
                                onMouseEnter={(e) => (e.currentTarget.style.cursor = "pointer")}
                    >
                        {composeSecurityCode()}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" gutterBottom
                                style={{color: 'white', position: "absolute", bottom: "73px", left: "25px"}}>
                        {t("cardHolder", {value: composeCardHolder()})}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6" gutterBottom
                                style={{color: 'white', position: "absolute", bottom: "35px", left: "25px"}}>
                        {t("availableBalance", {value: composeAvailableBalance()})}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" gutterBottom
                                style={{color: 'white', position: "absolute", bottom: "18px", left: "25px"}}>
                        {t("dailyPurchaseLimit", {value: composeDailyLimit()})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" gutterBottom
                                style={{color: 'white', position: "absolute", bottom: "18px", right: "15px"}}>
                        {composeCardCurrency()}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <IconButton
                        onClick={handleToggleDetails}
                        style={{color: 'white', position: "absolute", top: "15px", right: "15px"}}
                    >
                        {showDetails ? <VisibilityOff/> : <Visibility/>}
                    </IconButton>
                </Grid>
            </Grid>
        </CardContent>
    )
}

export default CardDetailInfo;
