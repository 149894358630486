import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {CardApplyStatus, CardStatus, CreditCardPreview} from "../../../types";
import cardStyle from "../../../styles/creditCardStyle";
import {useNavigate} from "react-router-dom";
import {mapCardApplyStatus, mapCardLevel, mapCardStatus} from "../../../utils/transformations";
import {activateCardStatusApi, fetchCardStatusApi} from "../../../api/cardsService";
import useNotification from "../../../useNotification";
import {useAuth0} from "@auth0/auth0-react";
import {updateCard} from "../../../redux/cards";
import {useDispatch} from "react-redux";
import {Skeleton, Zoom} from "@mui/material";
import Box from "@mui/material/Box";

function CreditCard({card}: { card: CreditCardPreview }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {getAccessTokenSilently} = useAuth0();
    const {notify} = useNotification();
    const {t} = useTranslation();
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dialogContent, setDialogContent] = useState("");

    function composeCreditCardNumber(value: string) {
        return `**** **** **** ${value}`;
    }

    const handleClickOpen = () => {
        if (card.id === '') return;
        const cardApplyStatus = mapCardApplyStatus(card.applyStatus);
        const cardStatus = mapCardStatus(card.status);
        if (cardApplyStatus === CardApplyStatus.UNDEFINED || cardApplyStatus === CardApplyStatus.WAIT_AUDIT || cardApplyStatus === CardApplyStatus.CARD_INIT) {
            setDialogContent(t("confirmStatusUpdate"));
            setOpenDialog(true);
        } else if (cardApplyStatus === CardApplyStatus.AUDIT_PASS && cardStatus == CardStatus.PENDING_ACTIVE) {
            setDialogContent(t("confirmActivationRequest"));
            setOpenDialog(true);
        } else {
            navigate(`/card/${card.cardNoLast4}`);
        }
    };

    const handleDialogClose = async (confirm: boolean) => {
        setOpenDialog(false);
        if (confirm) {
            try {
                setLoading(true);
                const token = await getAccessTokenSilently();
                if (token == null) {
                    notify(t('authFailed'), "warning");
                } else {
                    const cardApplyStatus = mapCardApplyStatus(card.applyStatus);

                    let promise;
                    if (cardApplyStatus === CardApplyStatus.UNDEFINED || cardApplyStatus === CardApplyStatus.WAIT_AUDIT || cardApplyStatus === CardApplyStatus.CARD_INIT) {
                        promise = fetchCardStatusApi(token, card.id);
                    } else if (cardApplyStatus === CardApplyStatus.AUDIT_PASS && cardStatus === CardStatus.PENDING_ACTIVE) {
                        promise = activateCardStatusApi(token, card.id);
                    } else {
                        promise = Promise.reject();
                    }
                    promise.then((response) => {
                        dispatch(updateCard(response.data));
                    });
                    promise.catch((e) => {
                        notify(t(e.response.data), "error")
                    });
                    promise.finally(() => setLoading(false));
                }
            } catch (err) {
                notify(t("failedToFetchCardDetails"), "error");
            }
        }
    };

    const cardStatus = mapCardStatus(card.status);
    const cardApplyStatus = mapCardApplyStatus(card.applyStatus);
    const cardLevel = mapCardLevel(card.cardLevel)

    return (
        <>
            {loading ?
                <Box sx={{
                    maxWidth: '374px',
                    width: '100%',
                    display: 'block',
                    height: '220px',
                    overflow: 'hidden',
                    borderRadius: '15px'
                }}>
                    <Skeleton width={'100%'} height={'inherit'} variant={'rectangular'}></Skeleton>
                </Box> :
                <Zoom in={true}>
                    <Card sx={cardStyle(cardStatus, cardApplyStatus, cardLevel)} onClick={handleClickOpen}>
                        <CardContent>
                            {cardStatus !== CardStatus.ACTIVE &&
                                <Typography variant="h6" gutterBottom style={{
                                    position: 'absolute',
                                    top: '25px',
                                    left: '45px'
                                }}>
                                    {t(cardStatus == CardStatus.UNDEFINED? cardApplyStatus : cardStatus)}
                                </Typography>
                            }
                            <Typography variant="h4" gutterBottom
                                        style={{position: 'absolute', top: '85px', left: '45px'}}>
                                {composeCreditCardNumber(card.cardNoLast4)}
                            </Typography>
                            <Typography variant="body1" gutterBottom
                                        style={{position: 'absolute', bottom: '20px', left: '45px'}}>
                                {t("cardHolder", {value: "***"})}
                            </Typography>
                            <Typography variant="body2" gutterBottom
                                        style={{position: 'absolute', bottom: '55px', width: '80%', textAlign: 'end'}}>
                                {t("expires", {value: "**/**"})}
                            </Typography>
                        </CardContent>
                    </Card>
                </Zoom>}

            <Dialog
                open={openDialog}
                onClose={() => handleDialogClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t("confirmation")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogClose(false)} color="primary">
                        {t("cancel")}
                    </Button>
                    <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
                        {t("confirm")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CreditCard;
