import React, {ReactNode} from 'react';
import {styled, ThemeProvider} from '@mui/material/styles';
import {MaterialDesignContent, SnackbarProvider, SnackbarProviderProps} from 'notistack';
import walletTheme from "../../utils/CustomTheme";

interface CustomProviderProps {
    children: ReactNode;
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
        backgroundColor: '#2D7738',
    },
    '&.notistack-MuiContent-error': {
        backgroundColor: '#970C0C',
    },
}));

const AppSnackbarProvider: React.FC<SnackbarProviderProps & CustomProviderProps> = ({children, ...props}) => (
    <SnackbarProvider {...props}>{children}</SnackbarProvider>
);

const AppThemeProvider: React.FC<CustomProviderProps> = ({children}) => (
    <ThemeProvider theme={walletTheme}>
        <AppSnackbarProvider
            maxSnack={3}
            Components={{
                success: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
            }}
        >{children}
        </AppSnackbarProvider>
    </ThemeProvider>
);

export default AppThemeProvider;
