import Grid from "@mui/material/Grid";
import Section from "./_components/Section";
import * as React from "react";
import LanguagePicker from "../_components/LanguagePicker";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import {Divider, FormControl} from "@mui/material";

function ApplicationBlock() {
    const [t] = useTranslation();
    return (
        <Section container spacing={2} direction={'column'}>
            <FormControl margin={'dense'}>
                <Typography variant={'h6'} color={'secondary.contrastText'}>
                    {t('selectLanguage')}
                </Typography>
            </FormControl>
            <FormControl margin={'dense'}>
                <LanguagePicker fullWidth/>
            </FormControl>
            <Divider variant={'fullWidth'} sx={{mt:4, mb:2}}></Divider>
            <FormControl margin={'dense'}>
                <Typography variant={'h6'} color={'secondary.contrastText'}>
                    {t('instructions')}
                </Typography>
            </FormControl>
            <FormControl margin={'dense'}>
                <Link color="primary"  href={t('instructionURL')} variant={'h5'}>
                </Link>
                <Button type={'button'} variant={'contained'} href={t('instructionURL')}>
                    {t('download')}
                </Button>
            </FormControl>
        </Section>
    );
}

export default ApplicationBlock;
