import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../src/redux/users';
import cardsReducer from '../src/redux/cards';
const store = configureStore({
    reducer: {
        user: userReducer,
        creditCard: cardsReducer
    },
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
