import httpClient from './httpClient';
import {Balance, User} from "../types";

export const walletWithdrawalRequestApi = async (token: string, network: string, currency: string, address: string, amount: number) => {
    return httpClient.post<Balance>(`/wallets/withdrawal`,
        {
            network: network,
            currency: currency,
            address: address,
            amount: amount
        },
        {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
};

export const fetchWalletBalanceDataApi = async (token: string, network: string, currency: string) => {
    return httpClient.get<Balance>(`/wallets/${network}/${currency}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
};

export const generateWalletApi = async (token: string, network: string) => {
    return httpClient.post<User>(`/wallets/${network}`, {}, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
};
