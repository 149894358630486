import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import previewImage from '../../assets/images/preview.png';
import { useTranslation } from 'react-i18next';

export default function Hero() {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Box
            component="section"
            id="hero"
            sx={{
                width: '100%',
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundImage:
                    theme.palette.mode === 'light'
                        ? 'linear-gradient(180deg, #a6d6a4, #FFF)'
                        : `linear-gradient(${theme.palette.primary.dark}, ${alpha(theme.palette.primary.main, 0.0)})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                padding: { xs: 2, md: 4 },
            }}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    gap: 2,
                    marginTop: '60px'
                }}
            >
                <Stack spacing={2} sx={{
                    width: '100%', alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Typography
                        variant="h1"
                        sx={{
                            fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem', lg: '3.5rem' },
                            fontWeight: 'bold',
                            lineHeight: 1.2,
                            color: theme.palette.primary.main
                        }}
                    >
                        {t('hero.title')}
                    </Typography>
                    <Typography
                        variant="h6"
                        color="text.secondary"
                        sx={{
                            width: '100%', mx: 'auto', alignItems: 'center',
                            justifyContent: 'center',
                            color: theme.palette.secondary.main,
                        }}
                    >
                        {t('hero.subtitle')}
                    </Typography>
                    <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{
                            width: '100%', mx: 'auto', alignItems: 'center',
                            justifyContent: 'center',
                            color: theme.palette.text.secondary,
                        }}
                    >
                        {t('hero.description1')}
                    </Typography>
                    <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{
                            width: '100%', mx: 'auto', alignItems: 'center',
                            justifyContent: 'center',
                            color: theme.palette.text.secondary,
                        }}
                    >
                        {t('hero.description2')}
                    </Typography>
                    <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{
                            width: '100%', maxWidth: '600px', mx: 'auto', alignItems: 'center',
                            justifyContent: 'center',
                            color: theme.palette.text.secondary,
                        }}
                    >
                        {t('hero.description3')}
                    </Typography>
                </Stack>
                <Box
                    id="image"
                    sx={{
                        mt: { xs: 4, sm: 6 },
                        width: '100%',
                        height: 'auto',
                        backgroundImage: `url(${previewImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        borderRadius: '10px',
                        outline: `1px solid ${alpha(
                            theme.palette.primary.main,
                            0.5
                        )}`,
                        boxShadow:
                            theme.palette.mode === 'light'
                                ? `0 0 12px 8px ${alpha(theme.palette.primary.light, 0.2)}`
                                : `0 0 24px 12px ${alpha(theme.palette.primary.dark, 0.2)}`,
                        transition: 'transform 0.3s ease-in-out',
                        '&:hover': {
                            transform: 'scale(1.05)',
                        },
                        aspectRatio: '16/9',
                    }}
                    role="img"
                    aria-label={t('hero.imageAlt')}
                />
            </Container>
        </Box>
    );
}
