import {MenuItem, Select} from "@mui/material";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

export interface LanguagePickerProps {
    fullWidth?: boolean
}

export default function LanguagePicker({fullWidth} : LanguagePickerProps) {
    const {t, i18n} = useTranslation();
    const [language, setLanguage] = React.useState(i18n?.language || 'en');

    const handleLanguageChange = (newLanguage: string) => {
        i18n.changeLanguage(newLanguage).then();
        setLanguage(newLanguage);
    };
    useEffect(() => {
        setLanguage(i18n.language);
    }, [language, i18n, i18n.language]);

    return (
        <Select
            value={language}
            onChange={(event) => handleLanguageChange(event.target.value)}
            displayEmpty
            fullWidth={fullWidth ?? false}
            inputProps={{'aria-label': t('footer.languageSelectAriaLabel')}}
        >
            <MenuItem value="ar">{t('locales.ar')}</MenuItem>
            <MenuItem value="de">{t('locales.de')}</MenuItem>
            <MenuItem value="es">{t('locales.es')}</MenuItem>
            <MenuItem value="en">{t('locales.en')}</MenuItem>
            <MenuItem value="fr">{t('locales.fr')}</MenuItem>
            <MenuItem value="hi">{t('locales.hi')}</MenuItem>
            <MenuItem value="pl">{t('locales.pl')}</MenuItem>
            <MenuItem value="ru">{t('locales.ru')}</MenuItem>
            <MenuItem value="uk">{t('locales.uk')}</MenuItem>
            <MenuItem value="zh-CN">{t('locales.zh-CN')}</MenuItem>
        </Select>
    );
}






