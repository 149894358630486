import React from 'react';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import {SxProps, Theme} from "@mui/material";
import {OverridableStringUnion} from "@mui/types";
import {Variant} from "@mui/material/styles/createTypography";
import {TypographyPropsVariantOverrides} from "@mui/material/Typography/Typography";

interface IconTextProps {
    src: string;
    alt: string;
    iconSize: string | number;
    text: string;
    variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
    color?: string;
    sx?: SxProps<Theme>;
}

const IconText: React.FC<IconTextProps> = ({src, alt, iconSize, text, variant, color, sx}) => {
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box
                component="img"
                src={src}
                alt={alt}
                sx={{
                    width: iconSize,
                    height: iconSize,
                    marginRight: '8px',
                    opacity: 0.8
                }}
            />
            <Box
                sx={{
                    lineHeight: iconSize,
                }}
            >
                <Typography
                    component='text'
                    variant={variant}
                    color={color}
                    noWrap
                    sx={sx}
                >
                    {text}
                </Typography>
            </Box>
        </Box>
    );
};

export default IconText;

