import {createSlice} from '@reduxjs/toolkit';
import {User} from "../types";
import {RootState} from '../store';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: {} as User,
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
    },
});

export const {setUser} = userSlice.actions;

export const selectUser = (state: RootState): User => state.user.user;

export default userSlice.reducer;
