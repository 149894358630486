import {CardApplyStatus, CardLevel, CardStatus} from "../types";

const getStatusColor = (status: CardStatus) => {
    switch (status) {
        case CardStatus.ACTIVE:
            return '#81c784'; // Softer green
        case CardStatus.CANCELED:
            return '#e57373'; // Softer red
        case CardStatus.INIT:
            return '#b0bec5'; // Softer grey
        case CardStatus.LOCK:
            return '#ffb74d'; // Softer orange
        case CardStatus.PENDING_ACTIVE:
            return '#64b5f6'; // Softer blue
        case CardStatus.PENDING_ISSUE:
            return '#ba68c8'; // Softer purple
        case CardStatus.SUSPENDED:
            return '#ff8a65'; // Softer coral
        case CardStatus.UNDEFINED:
            return 'white'; // Default color
        default:
            return '#3b5998'; // Default fallback color
    }
};

const getApplyStatusColor = (status: CardApplyStatus): string => {
    switch (status) {
        case CardApplyStatus.APPLYING:
            return '#A5D6A7'; // Softer green
        case CardApplyStatus.WAIT_AUDIT:
            return '#90CAF9'; // Softer blue
        case CardApplyStatus.AUDIT_PASS:
            return '#FFF59D'; // Softer yellow
        case CardApplyStatus.CARD_INIT:
            return '#81D4FA'; // Softer light blue
        case CardApplyStatus.AUDIT_REFUSE:
            return '#EF9A9A'; // Softer red
        case CardApplyStatus.UNDEFINED:
            return '#CFD8DC'; // Softer grey
        default:
            return '#3B5998'; // Default fallback color
    }
};

const getLevelColor = (level: CardLevel) => {
    switch (level) {
        case CardLevel.UNDEFINED:
            return "white";
        case CardLevel.PLATINUM:
            return '#d3d3d3'; // Elegant silver
        case CardLevel.CLASSIC_1:
            return '#dcdcdc'; // Lighter grey
        case CardLevel.CLASSIC_2:
            return '#bc8f8f'; // Muted bronze
        default:
            return '#3b5998'; // Default color
    }
};

function getFontColor(applyStatus: CardApplyStatus, level: CardLevel) {
    if (level === CardLevel.UNDEFINED || applyStatus === CardApplyStatus.UNDEFINED) {
        return "black";
    }
    if (level === CardLevel.PLATINUM || level === CardLevel.CLASSIC_1 || level === CardLevel.CLASSIC_2) {
        return "black"; // Use black font for better contrast on lighter backgrounds
    }
    return 'white'; // Default to white for other cases
}

const cardStyle = (status: CardStatus, applyStatus: CardApplyStatus, level: CardLevel, withHover: boolean = true) => {
    const statusColor = status == CardStatus.UNDEFINED ? getApplyStatusColor(applyStatus) : getStatusColor(status);
    const levelColor = getLevelColor(level);
    const fontColor = getFontColor(applyStatus, level);
    const baseStyles = {
        backgroundColor: levelColor,
        color: fontColor,
        borderRadius: '15px',
        padding: '20px',
        maxWidth: '374px',
        width: '100%',
        display: 'block',
        height: '220px',
        position: 'relative',
        overflow: 'hidden',
        border: `3px solid ${statusColor}`
    }
    if (withHover) {
        return {
            ...baseStyles,
            transition: 'background-color 0.3s, transform 0.3s',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: levelColor,
                transform: 'scale(1.05)'
            }
        }
    }

    return baseStyles;
};

export default cardStyle;
