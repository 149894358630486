import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://lucrumwallet.net/api';

const httpClient: AxiosInstance = axios.create({
    baseURL: API_URL,
});

// @ts-ignore
httpClient.interceptors.request.use((config: AxiosRequestConfig) => {
        console.log(`>> ${config.url}`, config?.data);
        return config;
    },
    (error: Error) => {
        console.error('Request error:', error);
        return Promise.reject(error);
    }
);

httpClient.interceptors.response.use((response: AxiosResponse) => {
        console.log(`<< ${response.config.url}`, response);
        return response;
    },
    (error: Error) => {
        console.error('Response error:', error);
        return Promise.reject(error);
    }
);

export default httpClient;
