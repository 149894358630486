import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SaveButton from "./_components/SaveButton";
import {KycDetailsForm, KycStatus} from "../../types";
import {fetchKycStatusApi, uploadKycApi} from "../../api/usersService";
import {selectUser, setUser} from "../../redux/users";
import {useDispatch, useSelector} from "react-redux";
import {mapKycStatus} from "../../utils/transformations";
import useNotification from '../../useNotification';
import {useTranslation} from 'react-i18next';
import {useAuth0} from "@auth0/auth0-react";
import IconText from "../_components/IconText";
import {allowedToRegisterCountries} from "../../constants/countries/allowedToRegisterCountries";
import {countriesData} from "../../constants/countries/countriesData";
import {Autocomplete} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

function KnowYourCustomerBlock() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {getAccessTokenSilently} = useAuth0();
    const {notify} = useNotification();
    const [files, setFiles] = useState<File[]>([]);
    const [fileNames, setFileNames] = useState<string[]>([]); // State to hold file names
    const user = useSelector(selectUser);
    const [kycStatus, setKycStatus] = useState<KycStatus>(KycStatus.UNDEFINED);
    const [kycData, setKycData] = useState<KycDetailsForm>({
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        occupation: '',
        annualIncome: '',
        documentType: '',
        documentNumber: '',
        documentCountry: '',
        documentExpiryDate: '',
        addressDetails: '',
        addressCity: '',
        addressCountry: '',
        addressPostCode: '',
        nickName: '',
        contactPhone: ''
    });
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (user != null) {
            const newStatus = mapKycStatus(user.kyc)
            if (newStatus !== kycStatus) {
                setKycStatus(newStatus);
            }
        }
    }, [user, user.kyc, kycStatus]);

    const validateFields = () => {
        const newErrors: { [key: string]: string } = {};

        if (!kycData.firstName || kycData.firstName.length > 50) {
            newErrors.firstName = t('fieldRequired') + ' (max 50 characters)';
        }
        if (!kycData.lastName || kycData.lastName.length > 50) {
            newErrors.lastName = t('fieldRequired') + ' (max 50 characters)';
        }
        if (!kycData.dateOfBirth || new Date(kycData.dateOfBirth) >= new Date()) {
            newErrors.dateOfBirth = t('invalidDateOfBirth'); // Must be in the past
        }
        if (!kycData.occupation || kycData.occupation.length > 100) {
            newErrors.occupation = t('fieldRequired') + ' (max 100 characters)';
        }
        if (!kycData.annualIncome || !/^\d+(\.\d{1,2})?$/.test(kycData.annualIncome)) {
            newErrors.annualIncome = t('invalidAnnualIncome');
        }
        if (!kycData.documentType || !/^(1|2)$/.test(kycData.documentType)) {
            newErrors.documentType = t('invalidDocumentType');
        }
        if (!kycData.documentNumber || kycData.documentNumber.length > 20) {
            newErrors.documentNumber = t('fieldRequired') + ' (max 20 characters)';
        }
        if (!kycData.documentCountry || kycData.documentCountry.length > 100) {
            newErrors.documentCountry = t('fieldRequired') + ' (max 100 characters)';
        }
        if (!kycData.documentExpiryDate || new Date(kycData.documentExpiryDate) <= new Date()) {
            newErrors.documentExpiryDate = t('invalidDocumentExpiryDate'); // Must be in the future
        }
        if (kycData.addressDetails && kycData.addressDetails.length > 200) {
            newErrors.addressDetails = t('maxLengthExceeded', {max: 200});
        }
        if (kycData.addressCity && kycData.addressCity.length > 100) {
            newErrors.addressCity = t('maxLengthExceeded', {max: 100});
        }
        if (kycData.addressCountry && kycData.addressCountry.length > 100) {
            newErrors.addressCountry = t('maxLengthExceeded', {max: 100});
        }
        if (kycData.addressPostCode && !/^\d{5}$/.test(kycData.addressPostCode)) {
            newErrors.addressPostCode = t('invalidPostCode');
        }
        if (kycData.nickName && kycData.nickName.length > 50) {
            newErrors.nickName = t('maxLengthExceeded', {max: 50});
        }
        if (kycData.contactPhone && !/^\+?[1-9]\d{1,14}$/.test(kycData.contactPhone)) {
            newErrors.contactPhone = t('invalidPhoneNumber');
        }
        if (files.length < 2) {
            newErrors['files'] = t('bothImagesRequired');
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleUpdateClick = async () => {
        try {
            setLoading(true);

            const token = await getAccessTokenSilently();
            if (token == null) {
                notify(t('authFailed'), "warning");
            } else {
                fetchKycStatusApi(token)
                    .then((response) => {
                        setKycStatus(mapKycStatus(response.data.kyc))
                        dispatch(setUser(response.data));
                        notify(t('kycUpdatedSuccess'), "info");
                    })
                    .catch((error) => {
                        notify(`${t('kycUpdatedError')}: ${error.message}`, "error");
                    });
            }
        } catch (error) {
            console.error('Error fetching KYC status:', error);
            notify(`${t('unexpectedError')}: ${error}`, "error");
        }
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            if (validateFields()) {
                const token = await getAccessTokenSilently();
                if (token == null) {
                    notify(t('authFailed'), "warning");
                } else {
                    uploadKycApi(token, files, kycData)
                        .then((response) => {
                            setKycStatus(mapKycStatus(response.data.kyc))
                            dispatch(setUser(response.data));
                            notify(t('kycSubmissionSuccess'), "success");
                        })
                        .catch((error) => {
                            notify(`${t('kycSubmissionError')}: ${error.message}`, "error");
                        }).finally(
                        () => {
                            setLoading(false);
                        }
                    );
                }
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error('Error uploading KYC:', error);
            notify(`${t('unexpectedError')}: ${error}`, "error");
        }
    };

    const handleAllAllowedInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        const regex = /^[a-zA-Z0-9_,.\s-]*$/;

        if (regex.test(value) || value === '') {
            setKycData({...kycData, [name]: value});
        }
    };

    const handleNumbersInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        const regex = /^[0-9]*$/;

        if (regex.test(value) || value === '') {
            setKycData({...kycData, [name]: value});
        }
    };

    const handlePhoneInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        const regex = /^\+?[0-9]*$/;

        if (regex.test(value) || value === '') {
            setKycData({...kycData, [name]: value});
        }
    };

    const handleOnlyLatinInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        const regex = /^[A-Za-z]*$/;

        if (regex.test(value) || value === '') {
            setKycData({...kycData, [name]: value});
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setKycData({...kycData, [name]: value});
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        if (event.target.files) {
            const selectedFile = event.target.files[0];
            if (selectedFile) {
                const maxSizeMB = 2;
                const maxSize = maxSizeMB * 1024 * 1024; // 2MB in bytes

                if (selectedFile.size > maxSize) {
                    notify(`${t('fileSizeError', {maxSizeMB})}`, "error");
                    event.target.value = ''; // Clear the input value
                    return;
                }
            }
            setFiles(prevFiles => {
                const updatedFiles = [...prevFiles];
                updatedFiles[index] = selectedFile;
                return updatedFiles;
            });
            setFileNames(prevFileNames => {
                const updatedFileNames = [...prevFileNames];
                updatedFileNames[index] = selectedFile.name;
                return updatedFileNames;
            });
        }
    };

    if (kycStatus === KycStatus.ADOPT_PASS) {
        return (
            <Typography variant="h6" color="secondary.contrastText">
                {t('kycVerified')}
            </Typography>
        );
    }

    if (kycStatus === KycStatus.IN_AUDIT || kycStatus === KycStatus.WAIT_AUDIT) {
        return (
            <div>
                <Typography variant="h6" color="primary" pb={3}>
                    {t('kycProcessing')}
                </Typography>
                <Button variant="contained" color="primary" onClick={handleUpdateClick} disabled={loading}>
                    {loading ? t('loading') : t('updateKyc')}
                </Button>
            </div>
        );
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Alert severity="info" variant={'outlined'}>
                    {t("allowedSymbols")}
                </Alert>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={t('firstName')}
                    name="firstName"
                    fullWidth
                    variant="outlined"
                    value={kycData.firstName}
                    onChange={handleOnlyLatinInputChange}
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                    required
                    disabled={loading}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={t('lastName')}
                    name="lastName"
                    fullWidth
                    variant="outlined"
                    value={kycData.lastName}
                    onChange={handleOnlyLatinInputChange}
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                    required
                    disabled={loading}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={t('dateOfBirth')}
                    name="dateOfBirth"
                    type="date"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{shrink: true}}
                    value={kycData.dateOfBirth}
                    onChange={handleInputChange}
                    error={!!errors.dateOfBirth}
                    helperText={errors.dateOfBirth}
                    required
                    disabled={loading}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={t('nickName')}
                    name="nickName"
                    fullWidth
                    variant="outlined"
                    value={kycData.nickName}
                    onChange={handleAllAllowedInputChange}
                    error={!!errors.nickName}
                    helperText={errors.nickName}
                    disabled={loading}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={t('contactPhone')}
                    name="contactPhone"
                    fullWidth
                    variant="outlined"
                    value={kycData.contactPhone}
                    onChange={handlePhoneInputChange}
                    error={!!errors.contactPhone}
                    helperText={errors.contactPhone}
                    disabled={loading}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={t('occupation')}
                    name="occupation"
                    fullWidth
                    variant="outlined"
                    value={kycData.occupation}
                    onChange={handleAllAllowedInputChange}
                    error={!!errors.occupation}
                    helperText={errors.occupation}
                    required
                    disabled={loading}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={t('annualIncome')}
                    name="annualIncome"
                    fullWidth
                    variant="outlined"
                    value={kycData.annualIncome}
                    onChange={handleNumbersInputChange}
                    error={!!errors.annualIncome}
                    helperText={errors.annualIncome}
                    required
                    disabled={loading}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    select
                    label={t('documentType')}
                    name="documentType"
                    fullWidth
                    variant="outlined"
                    value={kycData.documentType}
                    onChange={handleInputChange}
                    error={!!errors.documentType}
                    helperText={errors.documentType}
                    required
                    disabled={loading}
                >
                    <MenuItem value="1">{t('nationalIdCard')}</MenuItem>
                    <MenuItem value="2">{t('internationalPassport')}</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={t('documentNumber')}
                    name="documentNumber"
                    fullWidth
                    variant="outlined"
                    value={kycData.documentNumber}
                    onChange={handleInputChange}
                    error={!!errors.documentNumber}
                    helperText={errors.documentNumber}
                    required
                    disabled={loading}
                />
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    options={allowedToRegisterCountries}
                    getOptionLabel={(option) => t(`documentCountries.${option.alpha2}`)}
                    renderOption={(props, option) => (
                        <MenuItem key={option.alpha2} {...props}>
                            <IconText
                                text={t(`documentCountries.${option.alpha2}`)}
                                alt={t(`documentCountries.${option.alpha2}`)}
                                src={countriesData
                                    .find(country => country.abbreviation.toLowerCase() === option.alpha2.toLowerCase())?.flagBase64 ?? ""}
                                iconSize={"24px"}
                            />
                        </MenuItem>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("documentCountry")}
                            name={"documentCountry"}
                            variant="outlined"
                            fullWidth
                            error={!!errors.documentCountry}
                            helperText={errors.documentCountry}
                            required
                            disabled={loading}
                        />
                    )}
                    value={allowedToRegisterCountries.find(c => c.alpha2 === kycData.documentCountry) || null}
                    onChange={(_, newValue) => setKycData({...kycData, ["documentCountry"]: newValue?.alpha2 ?? ""})}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={t('documentExpiryDate')}
                    name="documentExpiryDate"
                    type="date"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{shrink: true}}
                    value={kycData.documentExpiryDate}
                    onChange={handleInputChange}
                    error={!!errors.documentExpiryDate}
                    helperText={errors.documentExpiryDate}
                    required
                    disabled={loading}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={t('addressDetails')}
                    name="addressDetails"
                    fullWidth
                    variant="outlined"
                    value={kycData.addressDetails}
                    onChange={handleAllAllowedInputChange}
                    error={!!errors.addressDetails}
                    helperText={errors.addressDetails}
                    disabled={loading}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={t('addressCity')}
                    name="addressCity"
                    fullWidth
                    variant="outlined"
                    value={kycData.addressCity}
                    onChange={handleOnlyLatinInputChange}
                    error={!!errors.addressCity}
                    helperText={errors.addressCity}
                    disabled={loading}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={t('addressCountry')}
                    name="addressCountry"
                    fullWidth
                    variant="outlined"
                    value={kycData.addressCountry}
                    onChange={handleOnlyLatinInputChange}
                    error={!!errors.addressCountry}
                    helperText={errors.addressCountry}
                    disabled={loading}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={t('addressPostCode')}
                    name="addressPostCode"
                    fullWidth
                    variant="outlined"
                    value={kycData.addressPostCode}
                    onChange={handleNumbersInputChange}
                    error={!!errors.addressPostCode}
                    helperText={errors.addressPostCode}
                    disabled={loading}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1" margin={'dense'}>{t('uploadDocumentImages')}*</Typography>
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    component="label"
                    disabled={loading}
                >
                    {t('uploadFrontPage')}
                    <input
                        type="file"
                        name="frontImage"
                        hidden
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, 0)}
                    />
                </Button>
                {errors.files && <Typography color="error">{errors.files}</Typography>}
                {fileNames[0] && (
                    <Typography variant="body2" color="primary">
                        <CheckCircleIcon/> {fileNames[0]}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    component="label"
                    disabled={loading}
                >
                    {t('uploadBackPage')}
                    <input
                        type="file"
                        name="backImage"
                        hidden
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, 1)}
                    />
                </Button>
                {errors.files && <Typography color="error" margin={'dense'}>{errors.files}</Typography>}
                {fileNames[1] && (
                    <Typography variant="body2" color="primary" margin={'dense'}>
                        <CheckCircleIcon/> {fileNames[1]}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={12}>
                <SaveButton variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
                    {loading ? <CircularProgress size={24}/> : t("submitKyc")}
                </SaveButton>
            </Grid>
        </Grid>
    );
}

export default KnowYourCustomerBlock;
