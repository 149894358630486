import React from "react";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import AppRoutes from "./AppRoutes";
import './styles/index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Auth0Provider, Auth0ProviderOptions} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";

const getEnvVariable = (key: string): string => {
    const value = process.env[key];
    if (!value) {
        throw new Error(`Environment variable ${key} is not defined`);
    }
    return value;
};

const CLIENT_ID = getEnvVariable("REACT_APP_OKTA_CLIENT_ID");
const DOMAIN = getEnvVariable("REACT_APP_OKTA_DOMAIN");
const REDIRECT_URI = getEnvVariable("REACT_APP_OKTA_REDIRECT_URI");
const AUDIENCE = getEnvVariable("REACT_APP_OKTA_AUDIENCE");

const App: React.FC = () => {
    const queryClient = new QueryClient();
    const navigate = useNavigate();

    const onRedirectCallback = (appState?: { returnTo?: string }) => {
        navigate(appState?.returnTo || '/');
    };

    const authProviderConfig: Auth0ProviderOptions = {
        domain: DOMAIN,
        clientId: CLIENT_ID,
        onRedirectCallback,
        authorizationParams: {
            redirect_uri: REDIRECT_URI,
            ...(AUDIENCE ? {audience: AUDIENCE} : {}),
            scope: "openid email",
        },
        cacheLocation: "localstorage",
        useRefreshTokens: true,
        leeway: 60,
    };

    return (
        <Auth0Provider {...authProviderConfig}>
            <QueryClientProvider client={queryClient}>
                <AppRoutes/>
            </QueryClientProvider>
        </Auth0Provider>
    );
};

export default App;
