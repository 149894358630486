import MenuIcon from "@mui/icons-material/Menu";
import {
    AppBar,
    Box,
    Container,
    Drawer,
    IconButton,
    Toolbar,
    Typography,
} from '@mui/material';
import * as React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/users";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import NavBar from "./NavBar";
import IconText from "../_components/IconText";

function TopBar() {
    const theme = useTheme();
    const {t} = useTranslation();
    const {user} = useAuth0();
    const internalUser = useSelector(selectUser);
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    const logoStyle = {
        width: 'auto',
        height: '50px',
        marginLeft: '20px',
        cursor: 'pointer',
    };

    return (
        <AppBar
            position="fixed"
            sx={{
                boxShadow: 0,
                bgcolor: 'transparent',
                backgroundImage: 'none',
                mt: 2,
            }}
        >

            <Container maxWidth={'lg'}>
                <Toolbar
                    variant="regular"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexShrink: 0,
                        borderRadius: 999,
                        bgcolor: theme.palette.primary.main,
                        backdropFilter: 'blur(24px)',
                        maxHeight: 40,
                        border: '1px solid',
                        borderColor: 'divider',
                        boxShadow: theme.palette.mode === 'light' ?
                            '0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)' :
                            '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
                    }}
                >
                    <Box sx={{display: {xs: 'flex', md: 'flex'}}}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                            sx={{
                                ml: '-6px',
                                mr: '6px',
                                display:{lg:'none'}
                            }}

                        >
                            <MenuIcon/>
                        </IconButton>
                        <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
                            <Box
                                sx={{
                                    width: 260,
                                    p: 2,
                                    backgroundColor: 'background.paper',
                                    flexGrow: 1,
                                    display:{lg:'none'}
                                }}
                                role="presentation"
                                onClick={toggleDrawer(false)}
                                onKeyDown={toggleDrawer(false)}
                            >
                                <IconText
                                    src={require('../../assets/images/logo.png')}
                                    alt="logo" iconSize={'64px'}
                                    text={t('lucrumWallet')}
                                    variant="h6"
                                    color={theme.palette.primary.main}
                                    sx={{ fontWeight:"bold"}}
                                />
                                <NavBar/>
                            </Box>
                        </Drawer>
                    </Box>
                    <Box sx={{display: {xs: 'none', lg: 'flex'}, ml:-2}}>
                        <img
                            src={require('../../assets/images/logo.png')}
                            style={logoStyle}
                            alt="logo"
                        />
                    </Box>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{flexGrow: 1, ml: 2}}
                    >
                        {t('hi', {value: internalUser?.nickname || user?.email || "%username%"})}!
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>

    )
}

export default TopBar;
