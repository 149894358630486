import httpClient from "./httpClient";
import {CryptoTransaction} from "../types";

export const fetchCryptoTransactionsApi = async (token: string) => {
    return httpClient.get<CryptoTransaction[]>(`/transactions`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
};
