import {CryptoCurrency, CryptoNetworks} from "../types";

interface CurrencyOption {
    value: string;
    text: string;
    alt: string;
    src: string;
    supportedNetwork: string[];
}

const currencyOptions:CurrencyOption[] = [
    {
        value: CryptoCurrency.USDT,
        text: 'USDT',
        alt: 'USDT',
        src: require('../assets/images/tether.png'),
        supportedNetwork: [CryptoNetworks.ARBITRUM, CryptoNetworks.BSC, CryptoNetworks.ERC20, /** CryptoNetworks.TRC20 */]
    },
    {
        value: CryptoCurrency.USDC,
        text: 'USDC',
        alt: 'USDC',
        src: require('../assets/images/usdc.png'),
        supportedNetwork: [CryptoNetworks.ARBITRUM, CryptoNetworks.ERC20]
    },
];

export {currencyOptions}
