import * as React from 'react';
import {useTranslation} from 'react-i18next';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TwitterIcon from '@mui/icons-material/Twitter';
import LanguagePicker from "../_components/LanguagePicker";

const logoStyle = {
    width: '140px',
    height: 'auto',
};

export default function Footer() {
    const {t} = useTranslation();

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: {xs: 6, sm: 10},
                py: {xs: 4, sm: 8},
                px: {xs: 0, sm: 0, md: 0, xl: 0},
                textAlign: 'center',
                backgroundColor: 'background.paper',
                color: 'text.primary',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column', sm: 'row'},
                    width: '100%',
                    justifyContent: 'space-around',
                    gap: {xs: 4, sm: 8},
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                        width: {xs: '100%', sm: '140px'},
                        height: {xs: '140px'},
                    }}
                >
                    <Box>
                        <img
                            src={require('../../assets/images/logo.png')}
                            style={logoStyle}
                            alt={t('footer.logoAlt')}
                        />
                    </Box>
                    {/*<Typography variant="body2" fontWeight={600} gutterBottom>*/}
                    {/*    {t('footer.newsletter')}*/}
                    {/*</Typography>*/}
                    {/*<Typography variant="body2" color="text.secondary" mb={2}>*/}
                    {/*    {t('footer.newsletterDescription')}*/}
                    {/*</Typography>*/}
                    {/*<Stack direction="row" spacing={1} useFlexGap>*/}
                    {/*    <TextField*/}
                    {/*        id="outlined-basic"*/}
                    {/*        hiddenLabel*/}
                    {/*        size="small"*/}
                    {/*        variant="outlined"*/}
                    {/*        fullWidth*/}
                    {/*        aria-label={t('footer.emailAriaLabel')}*/}
                    {/*        placeholder={t('footer.emailPlaceholder')}*/}
                    {/*        inputProps={{*/}
                    {/*            autoComplete: 'off',*/}
                    {/*            'aria-label': t('footer.emailAriaLabel'),*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*    <Button variant="contained" color="primary" sx={{flexShrink: 0}}>*/}
                    {/*        {t('footer.subscribe')}*/}
                    {/*    </Button>*/}
                    {/*</Stack>*/}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        width: {xs: '100%', sm: '20%'},
                        pt: {xs: '0', sm: '10px'}
                    }}
                >
                    <Typography variant="body2" fontWeight={600}>
                        {t('footer.product')}
                    </Typography>
                    <Link color="text.secondary" href="#">
                        {t('footer.features')}
                    </Link>
                    <Link color="text.secondary" href="#">
                        {t('footer.highlights')}
                    </Link>
                    <Link color="text.secondary" href="#">
                        {t('footer.faqs')}
                    </Link>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        width: {xs: '100%', sm: '20%'},
                        pt: {xs: '0', sm: '10px'}
                    }}
                >
                    <Typography variant="body2" fontWeight={600}>
                        {t('footer.company')}
                    </Typography>
                    <Link color="text.secondary" href="#">
                        {t('footer.aboutUs')}
                    </Link>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    pt: {xs: 4, sm: 8},
                    width: '100%',
                    borderTop: '1px solid',
                    borderColor: 'divider',
                    flexDirection: {xs: 'column', sm: 'row'},
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: {xs: 'center', sm: 'flex-start'},
                        gap: 1,
                    }}
                >
                    <Link color="text.secondary" href="#">
                        {t('footer.privacyPolicy')}
                    </Link>
                    <Link color="text.secondary" href="#">
                        {t('footer.termsOfService')}
                    </Link>
                    <Link color="text.secondary" href="mailto:support@lucrumwallet.net">
                        {'support@lucrumwallet.net'}
                    </Link>
                </Box>
                <Stack
                    direction="row"
                    justifyContent="center"
                    spacing={1}
                    useFlexGap
                    sx={{
                        color: 'text.secondary',
                        pt: {xs: 4, sm: 0},
                    }}
                >
                    <IconButton
                        color="inherit"
                        href="#"
                        aria-label={t('footer.twitter')}
                        sx={{alignSelf: 'center'}}
                    >
                        <TwitterIcon/>
                    </IconButton>
                </Stack>
                <Box sx={{pt: {xs: 4, sm: 0}}}>
                    <LanguagePicker/>
                </Box>
            </Box>
        </Container>
    );
}
