import React, {useEffect, useState} from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import cardStyle from "../../../styles/creditCardStyle";
import {fetchCardPrice, generateCardApi} from "../../../api/cardsService";
import {addCard} from "../../../redux/cards";
import useNotification from "../../../useNotification";
import {useTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import {useDispatch, useSelector} from "react-redux";
import {CardApplyStatus, CardLevel, CardPrice, CardStatus, CryptoNetworks} from "../../../types";
import {Autocomplete, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import IconText from "../../_components/IconText";
import {networkOptions} from "../../../constants/networkOptions";
import axios from "axios";
import {currencyOptions} from "../../../constants/currencyOptions";
import {countriesData} from "../../../constants/countries/countriesData";
import {fetchWalletBalanceDataApi} from "../../../api/walletsService";
import Typography from "@mui/material/Typography";
import {selectUser} from "../../../redux/users";
import Alert from "@mui/material/Alert";

function CardPlaceholder({setLoading}: { setLoading: (value: boolean) => void }) {
    const menuItemIconSize = '1.6rem';
    const dispatch = useDispatch();
    const {notify} = useNotification();
    const {getAccessTokenSilently} = useAuth0();
    const {t} = useTranslation();
    const [phoneCode, setPhoneCode] = useState<string>("");
    const [countryCode, setCountryCode] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [open, setOpen] = useState(false);
    const [network, setNetwork] = React.useState('');
    const [currency, setCurrency] = useState<string>('');
    const [balance, setBalance] = useState<string>("-");
    const [fee, setFee] = useState<string>("-");
    const [showNoWalletWarning, setShowNoWalletWarning] = useState<boolean>(false);
    const [cardPrice, setCardPrice] = useState<CardPrice | null>(null);
    const [isEnoughMoneyToPurchaseCard, setIsEnoughMoneyToPurchaseCard] = useState<boolean>(false);
    const styles = cardStyle(CardStatus.UNDEFINED, CardApplyStatus.UNDEFINED, CardLevel.UNDEFINED, false);
    const sortedCountriesByPhoneCode = countriesData.sort((a, b) => a.phoneCode - b.phoneCode);
    const user = useSelector(selectUser);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleGenerateCard = async () => {
        if (!phoneCode || !phoneNumber) {
            notify(t("pleaseFillInPhone"), "warning");
            return;
        }
        if (!network) {
            notify(t("pleaseFillInNetwork"), "warning");
            return;
        }
        setLoading(true);
        try {
            const token = await getAccessTokenSilently();
            if (token == null) {
                notify(t('authFailed'), "warning");
            } else {
                const response = await generateCardApi(token, phoneCode, phoneNumber, network, currency);
                dispatch(addCard(response.data));
                notify(t("cardGeneratedSuccessfully"), "success");
                handleClose();
            }
        } catch (err) {
            if (axios.isAxiosError(err)) {
                notify(t(err.response?.data.toString()), "error");
            }
        } finally {
            setLoading(false);
        }
    };

    const checkWalletBalance = async (network: string, currency: string) => {
        const token = await getAccessTokenSilently();
        if (token == null) {
            notify(t('authFailed'), "warning");
        } else {
            fetchWalletBalanceDataApi(token, network, currency)
                .then((response) => {
                    setBalance(response.data.amount);
                    setBalance(response.data.fee.toString());
                    notify("Balance updated", "info");
                })
                .catch((error) => {
                    notify(error, "error");
                });
        }
    };

    const retrieveCardPrice = async () => {
        const token = await getAccessTokenSilently();
        if (token == null) {
            notify(t('authFailed'), "warning");
        } else {
            fetchCardPrice(token)
                .then((response) => {
                    setCardPrice(response.data);
                })
                .catch((error) => {
                    notify(error, "error");
                });
        }
    };

    const getUserAddress = () => {
        if (network?.length > 0 && user !== undefined && user !== null) {
            switch (network) {
                case CryptoNetworks.ERC20:
                case CryptoNetworks.BSC:
                case CryptoNetworks.ARBITRUM:
                    return user.ethCryptoAddress;
                case CryptoNetworks.TRC20:
                    return user.trxCryptoAddress;
            }
        }
        return '';
    }

    useEffect(() => {
        if (network?.length > 0 && currency?.length > 0) {
            if (getUserAddress() !== null) {
                checkWalletBalance(network, currency);
            } else {
                setBalance('');
                setFee('');
            }
        }
    }, [network, currency]);

    useEffect(() => {
        setShowNoWalletWarning(getUserAddress() === null && network !== '');
    }, [network]);

    useEffect(() => {
        if (cardPrice !== null) {
            setIsEnoughMoneyToPurchaseCard(!Number.isNaN(Number(balance)) && (Number(balance) >= (Number(cardPrice.price) - Number(cardPrice.discount))));
        }
    }, [cardPrice, balance]);

    useEffect(() => {
        retrieveCardPrice();
    }, []);

    return (
        <>
            <Card sx={{...styles}} onClick={handleClickOpen}>
                <CardContent sx={{textAlign: 'center', height: '100%', display: 'flex', justifyContent: "center"}}>
                    <AddIcon sx={{fontSize: '48px', color: '#ccc', alignSelf: "center"}}/>
                </CardContent>
            </Card>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{t("createCreditCard")}</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        options={sortedCountriesByPhoneCode}
                        getOptionLabel={(option) => `+${option.phoneCode.toString()} (${option.abbreviation})`}
                        renderOption={(props, option) => (
                            <MenuItem key={option.phoneCode.toString() + option.abbreviation}  {...props}>
                                <IconText
                                    text={`+${option.phoneCode.toString()} (${option.abbreviation})`}
                                    alt={option.abbreviation.toLowerCase()}
                                    src={option.flagBase64}
                                    iconSize={"24px"}
                                />
                            </MenuItem>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("phoneCode")}
                                name={'phoneCode'}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                            />
                        )}
                        value={sortedCountriesByPhoneCode.find(c => c.phoneCode.toString() === phoneCode
                            && c.abbreviation.toLowerCase() === countryCode) || null}
                        onChange={(_, newValue) => {
                            setPhoneCode(newValue?.phoneCode.toString() ?? "")
                            setCountryCode(newValue?.abbreviation.toLowerCase() ?? "")
                        }}
                    />
                    <TextField
                        margin="dense"
                        label={t("phoneNumber")}
                        fullWidth
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        variant="outlined"
                    />
                    <FormControl fullWidth margin='dense'>
                        <InputLabel id={"network-select-label"}>{t("network")}</InputLabel>
                        <Select
                            labelId={"network-select-label"}
                            label={t("network")}
                            value={network}
                            onChange={(event) => setNetwork(event.target.value)}
                            fullWidth
                        >
                            {
                                networkOptions.map(nO => {
                                    return (
                                        <MenuItem key={'network-' + nO.value} value={nO.value}>
                                            <IconText text={t(nO.text)} alt={nO.alt}
                                                      src={nO.src}
                                                      iconSize={menuItemIconSize}/>
                                        </MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin='dense'>
                        <InputLabel id={"currency-select-label"}>{t("currency")}</InputLabel>
                        <Select
                            labelId={"currency-select-label"}
                            label={t("currency")}
                            value={currency}
                            onChange={(event) => setCurrency(event.target.value)}
                            fullWidth
                        >
                            {
                                currencyOptions.map(nO => {
                                    return (
                                        nO.supportedNetwork.includes(network ?? '') ?
                                            <MenuItem key={'network-' + nO.value} value={nO.value}>
                                                <IconText text={t(nO.text)} alt={nO.alt}
                                                          src={nO.src}
                                                          iconSize={menuItemIconSize}/>
                                            </MenuItem> : <></>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{color: 'secondary.contrastText'}} margin={'dense'}>
                        {
                            showNoWalletWarning ?
                                <FormControl fullWidth sx={{mb: 2}}>
                                    <Alert severity={'warning'}>{t("noWallet", {network: network})}</Alert>
                                </FormControl>
                                :
                                <>
                                    <FormControl fullWidth margin={'dense'}>
                                        <Typography>{t("walletBalance")}</Typography>
                                    </FormControl>
                                    <FormControl fullWidth sx={{textAlign: 'end'}}>
                                        <Typography>{balance + ' ' + currency}</Typography>
                                    </FormControl>
                                    <FormControl fullWidth margin={'dense'}>
                                        <Typography>{t("fee")}</Typography>
                                    </FormControl>
                                    <FormControl fullWidth sx={{textAlign: 'end'}}>
                                        <Typography>{fee + ' ' + currency}</Typography>
                                    </FormControl>
                                </>
                        }
                        <FormControl fullWidth>
                            <Typography>{t("subtotal")}</Typography>
                        </FormControl>
                        <FormControl fullWidth sx={{textAlign: 'end'}}>
                            <Typography>{currency === '' ? "$" : ''}{Number(cardPrice?.price).toFixed(2) + ' ' + currency}</Typography>
                        </FormControl>
                        <FormControl fullWidth>
                            <Typography>{t("discount")}</Typography>
                        </FormControl>
                        <FormControl fullWidth sx={{textAlign: 'end'}}>
                            <Typography>{currency === '' ? "$" : ''}{Number(cardPrice?.discount).toFixed(2) + ' ' + currency}</Typography>
                        </FormControl>
                        <FormControl fullWidth>
                            <Typography>{t("total")}</Typography>
                        </FormControl>
                        <FormControl fullWidth sx={{textAlign: 'end'}}>
                            <Typography
                                variant={'h6'}>{currency === '' ? "$" : ''}{(Number(cardPrice?.price) - Number(cardPrice?.discount)).toFixed(2) + ' ' + currency}
                            </Typography>
                        </FormControl>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t("cancel")}
                    </Button>
                    <Button onClick={handleGenerateCard} color="primary" disabled={!isEnoughMoneyToPurchaseCard}>
                        {t("confirm")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CardPlaceholder;
