import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {useEffect, useState} from "react";
import TopBar from "../components/internal/TopBar";
import {fetchUserDataApi} from "../api/usersService";
import {uploadReferralIdApi} from "../api/referralService";
import {Outlet} from "react-router-dom";
import {setUser} from "../redux/users";
import {useDispatch} from "react-redux";
import AppThemeProvider from "./_components/AppThemeProvider";
import useNotification from "../useNotification";
import CircularProgress from '@mui/material/CircularProgress';
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import {fetchAllCardsApi} from "../api/cardsService";
import {setCards} from "../redux/cards";
import {useTranslation} from "react-i18next";
import axios from "axios";
import NavBar from "../components/internal/NavBar";
import {Paper} from '@mui/material';

function InternalPage() {
    const {isAuthenticated, getAccessTokenSilently, error, isLoading, logout} = useAuth0();
    const {notify} = useNotification();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [internalUserLoading, setInternalUserLoading] = useState(true);

    const loadCards = async () => {
        try {
            const token = await getAccessTokenSilently();
            if (token == null) {
                notify(t('authFailed'), "warning");
            } else {
                fetchAllCardsApi(token)
                    .then((response) => {
                        dispatch(setCards(response.data));
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        } catch (err) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (error?.message?.length != null) {
            notify(error?.message, "error");
        }
    }, [error, notify]);

    useEffect(() => {
        const retrieveUser = async () => {
            const token = await getAccessTokenSilently();
            if (token == null) {
                return Promise.reject("No access token");
            }
            return fetchUserDataApi(token);
        };

        const updatePromoCode = async (code: String) => {
            const token = await getAccessTokenSilently();
            if (token == null) {
                return Promise.reject("No access token");
            }
            return uploadReferralIdApi(token, code);
        }

        if (isAuthenticated) {
            setInternalUserLoading(true);
            retrieveUser()
                .then((response) => {
                    dispatch(setUser(response.data));
                    loadCards();
                    const promoCode = sessionStorage.getItem('promo');
                    if (promoCode != null && !!promoCode.length) {
                        updatePromoCode(promoCode).catch(e => {
                            if (axios.isAxiosError(e)) {
                                notify(t(e.response?.data.toString()), 'error');
                            }
                        });
                    }
                })
                .catch((e) => {
                    console.error(e);
                    logout();
                })
                .finally(() => setInternalUserLoading(false));
        } else {
            logout()
                .then(() => setInternalUserLoading(false));
        }
    }, [dispatch, getAccessTokenSilently, isAuthenticated, logout]);

    return (
        <AppThemeProvider>
            {(isLoading || internalUserLoading) ? (
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                        <CircularProgress/>
                    </Box>
                ) :
                (
                    <Box sx={{display: 'flex'}}>
                        <CssBaseline/>
                        <TopBar/>
                        <Box
                            component="main"
                            sx={{
                                backgroundColor: (theme) =>
                                    theme.palette.mode === 'light'
                                        ? theme.palette.grey[100]
                                        : theme.palette.grey[900],
                                flexGrow: 1,
                                height: '100vh',
                                overflow: 'auto',
                            }}
                        >

                            <Container maxWidth="lg" sx={{pt: {xs: 11, sm: 12}, pb: 4}}>
                                <Grid container spacing={0} sx={{
                                    px: {xs: 1, sm: 2},
                                    minHeight: {
                                        /*space engineering, don`t touch!*/
                                        xs: 'calc(100vh - 24px - (8px * 8) - (8px * 11))',
                                        sm: 'calc(100vh - 24px - (8px * 8) - (8px * 12))'
                                    }
                                }}>
                                    < Grid item lg={3} pr={2} sx={{display: {xs: 'none', lg: 'block'}}}>
                                        <Paper
                                            sx={{
                                                p: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                boxShadow: 3,
                                                position: 'fixed',
                                                maxWidth: '250px',
                                                width: '250px'
                                            }}
                                        >
                                            <NavBar/>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        <Outlet/>
                                    </Grid>
                                </Grid>
                                <Box sx={{px: {xs: 1, sm: 2}, textAlign: 'end', mt: 4}}>
                                    {'support@lucrumwallet.net'}
                                </Box>
                            </Container>
                        </Box>
                    </Box>
                )}

        </AppThemeProvider>
    )
        ;
}

export default withAuthenticationRequired(InternalPage);
