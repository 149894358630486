import httpClient from './httpClient';
import {
    CardPrice,
    CardSensitiveResponse,
    CardTransaction,
    CreditCardDetails,
    CreditCardPreview,
    TopUpResponse
} from "../types";

export const fetchCardDataApi = async (token: string, id: string) => {
    return httpClient.get<CreditCardDetails>(`/cards/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
};

export const fetchAllCardsApi = async (token: string) => {
    return httpClient.get<CreditCardPreview[]>(`/cards`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
};

export const generateCardApi = async (token: string, phoneCode: string, phoneNumber: string, network: string, currency: string) => {
    return httpClient.post<CreditCardPreview>(`/cards`, {phoneCode, phoneNumber, network, currency}, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
};

export const fetchCardStatusApi = async (token: string, id: string) => {
    return httpClient.get<CreditCardPreview>(`/cards/status/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
};

export const activateCardStatusApi = async (token: string, id: string) => {
    return httpClient.get<CreditCardPreview>(`/cards/activate/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
};

export const topUpApi = async (token: string, cardId: string, amount: number, network: string, currency: string) => {
    return httpClient.post<TopUpResponse>(`/cards/top-up`, {
        amountInCents: amount,
        chain: network,
        currency: currency,
        cardId: cardId
    }, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
};

export const fetchCardTransactionsApi = async (token: string, cardId: string) => {
    return httpClient.get<CardTransaction[]>(`/cards/transactions/${cardId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
};

export const fetchCardSensitiveUrlApi = async (token: string, id: string) => {
    return httpClient.get<CardSensitiveResponse>(`/cards/sensitive/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
};

export const changeCardPinApi = async (token: string, id: string, pin: string) => {
    return httpClient.post<string>(`/cards/change-pin/${id}`, pin, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
};

export const changeCardPhoneNumberApi = async (token: string, id: string, phoneCode: string, phoneNumber: string) => {
    return httpClient.post<CreditCardPreview[]>(`/cards/update-phone/${id}`, {
        phoneCode: phoneCode,
        phoneNumber: phoneNumber
    }, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
};

export const lockCardApi = async (token: string, id: string) => {
    return httpClient.get<CreditCardPreview[]>(`/cards/lock/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
};

export const unlockCardApi = async (token: string, id: string) => {
    return httpClient.get<CreditCardPreview[]>(`/cards/unlock/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
};

export const fetchCardPrice = async (token: string) => {
    return httpClient.get<CardPrice>("/cards/price", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
};

// do not remove!
// export const fetchCardSensitiveUrlApi = async (token: string, id: string) => {
//     return httpClient.get<CardSensitiveInfo>(`/cards/sensitive/${id}`, {
//         headers: {
//             Authorization: `Bearer ${token}`,
//         },
//     })
// };


