import * as React from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Deposits from "./Deposits";
import CardTransactionsBlock from "../transactions/CardTransactionsBlock";
import {useEffect, useState} from "react";
import {CardStatus, CardTransaction, CreditCardDetails} from "../../types";
import {useSelector} from "react-redux";
import {selectCards} from "../../redux/cards";
import {fetchCardDataApi, fetchCardTransactionsApi} from "../../api/cardsService";
import {useAuth0} from "@auth0/auth0-react";
import useNotification from "../../useNotification";
import {useTranslation} from "react-i18next";
import ProgressLoader from "../_components/ProgressLoader";
import Link from "@mui/material/Link";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";

export default function Dashboard() {
    const {getAccessTokenSilently} = useAuth0();
    const {notify} = useNotification();
    const {t} = useTranslation();
    const [cardDetails, setCardDetails] = useState<CreditCardDetails | null>(null);
    const [cardTransaction, setCardTransaction] = useState<CardTransaction[] | null>(null);
    const [cardDetailsUpdated, setCardDetailsUpdated] = useState<string>('');
    const creditCardPreviewArray = useSelector(selectCards);
    const [cardLoading, setCardLoading] = useState(true);
    const [transactionsLoading, setTransactionsLoading] = useState(true);
    const navigate = useNavigate();

    const handleRedirectToTransactions = (event: React.MouseEvent) => {
        event.preventDefault();
        navigate('/transactions');
    };

    const retrieveCardInfo = async (cardId: string) => {
        const token = await getAccessTokenSilently();

        fetchCardDataApi(token, cardId)
            .then(
                response => {
                    setCardDetails(response.data);
                    setCardDetailsUpdated(new Date().toLocaleString());
                }
            )
            .catch(
                () => {
                    notify(t('retrieveCardError'), 'error');
                }
            )
            .finally(
                () => {
                    setCardLoading(false)
                }
            );
    }

    const retrieveCardTransactions = async (cardId: string) => {
        const token = await getAccessTokenSilently();

        fetchCardTransactionsApi(token, cardId)
            .then(
                response => {
                    console.log(response)
                    setCardTransaction(response.data);
                }
            )
            .catch(
                () => {
                    notify(t('retrieveTransactionsError'), 'error');
                }
            )
            .finally(
                () => {
                    setTransactionsLoading(false)
                }
            );
    }

    useEffect(() => {
        if (creditCardPreviewArray.length > 0) {
            const firstCard = creditCardPreviewArray[0];
            if (firstCard.status === CardStatus.ACTIVE) {
                const cardId = firstCard.id;
                retrieveCardInfo(cardId);
                retrieveCardTransactions(cardId);
                return;
            } else {
                setCardLoading(false);
                setTransactionsLoading(false);
            }
        } else {
            setCardLoading(false);
            setTransactionsLoading(false);
        }
    }, [creditCardPreviewArray.length]);

    if (cardDetails === null) {
        if (cardLoading || (creditCardPreviewArray.length > 0 && creditCardPreviewArray[0].status == CardStatus.ACTIVE)) {
            return (
                <ProgressLoader/>
            );
        }
        return (
            <Paper sx={{p: 2}}>
                <Typography align={'center'} variant={'h2'} color={'primary'} fontWeight={'bold'}>
                    Lucrum Wallet
                </Typography>
                <Typography align={'center'} variant={'h5'} mt={1}>
                    {t('homeMessage')} '{t('cards')}'
                    <Link color="primary" href={t('instructionURL')} variant={'h5'}>
                    <br/>
                    <br/>
                    {t('howToStart')}
                </Link>
                </Typography>
            </Paper>
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <Deposits cardDetails={cardDetails} updated={cardDetailsUpdated}/>
            </Grid>
            <Grid item xs={12} md={8}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: 3,
                    }}
                >
                    {transactionsLoading ? <ProgressLoader/> : !!cardTransaction &&
                        <>
                            <CardTransactionsBlock cardDetails={cardDetails} cardTransactions={cardTransaction}/>

                            <Link color="primary" href="#" onClick={handleRedirectToTransactions} sx={{mt: 3}}>
                                {t('seeMoreTransactions')}
                            </Link>
                        </>
                    }
                </Paper>
            </Grid>
        </Grid>
    );
}

