import ListItemButton from "@mui/material/ListItemButton";
import {Link} from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import HomeIcon from "@mui/icons-material/Home";
import ListItemText from "@mui/material/ListItemText";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import WalletIcon from "@mui/icons-material/Wallet";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SettingsIcon from "@mui/icons-material/Settings";
import Divider from "@mui/material/Divider";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import List from "@mui/material/List";
import * as React from "react";
import {useDispatch} from "react-redux";
import {useAuth0} from "@auth0/auth0-react";
import {People} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

function NavBar() {
    const {logout} = useAuth0();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    return (
        <List component="nav">
            <ListItemButton component={Link} to="">
                <ListItemIcon>
                    <HomeIcon/>
                </ListItemIcon>
                <ListItemText primary={t("home")}/>
            </ListItemButton>
            <ListItemButton component={Link} to="cards">
                <ListItemIcon>
                    <CreditCardIcon/>
                </ListItemIcon>
                <ListItemText primary={t("cards")}/>
            </ListItemButton>
            <ListItemButton component={Link} to="wallets">
                <ListItemIcon>
                    <WalletIcon/>
                </ListItemIcon>
                <ListItemText primary={t("wallets")}/>
            </ListItemButton>
            <ListItemButton component={Link} to="transactions">
                <ListItemIcon>
                    <ReceiptIcon/>
                </ListItemIcon>
                <ListItemText primary={t("transactions")}/>
            </ListItemButton>
            <ListItemButton component={Link} to="referral">
                <ListItemIcon>
                    <People/>
                </ListItemIcon>
                <ListItemText primary={t("referralProgram")}/>
            </ListItemButton>
            <ListItemButton component={Link} to="settings">
                <ListItemIcon>
                    <SettingsIcon/>
                </ListItemIcon>
                <ListItemText primary={t("settings")}/>
            </ListItemButton>
            <Divider sx={{my: 1}}/>
            <ListItemButton onClick={() => {
                logout()
                    .then(() => dispatch({type: 'user/logout'}));
            }}>
                <ListItemIcon>
                    <ExitToAppIcon/>
                </ListItemIcon>
                <ListItemText primary={t("logout")}/>
            </ListItemButton>
        </List>
    )
}

export default NavBar;
