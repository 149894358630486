import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {styled} from '@mui/material/styles';
import Title from './Title';
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTranslation} from "react-i18next";
import {CreditCardDetails} from "../../types";
import {getPrintDollarAmount} from "../../utils/moneyUtils";

const StyledPaper = styled(Paper)(({theme}) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    return ({
        padding: theme.spacing(2),
        marginRight: isTablet ? '0' : 'auto',
        margin: isMobile ? 'auto' : '0',
        maxWidth: isTablet ? 320 : isMobile ? 220 : 480,
        minWidth: 210,
        backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1c' : '#fff',
    });
});

export interface DepositsProps {
    cardDetails: CreditCardDetails;
    updated: String;
}

export default function Deposits({cardDetails, updated}: DepositsProps) {
    const {t} = useTranslation();

    return (
        <StyledPaper>
            <Box sx={{flexGrow: 1, p: 2}}>
                <Grid container spacing={2} direction="column" alignItems="center">
                    <Grid item>
                        <Title>{cardDetails.currency} {t('balance')}</Title>
                    </Grid>
                    <Grid item>
                        <Typography component="p" variant="h4" sx={{fontWeight: 'bold', color: 'primary.main'}}>
                            {getPrintDollarAmount(cardDetails.cardAvailableBalance)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography color="text.secondary" align={'center'}>
                            {t('lastUpdated') + updated}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Link color="primary" href={`card/${cardDetails.cardNoLast4}`} sx={{fontWeight: 'medium'}}>
                            {t('details')}
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </StyledPaper>
    );
}
