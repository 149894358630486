import {createTheme} from "@mui/material/styles";

const walletTheme = createTheme({
    palette: {
        primary: {
            light: '#a6d6a4',  // Lighter green
            main: '#21730A',   // New main theme color, softer green
            dark: '#4e9a4e',   // Dark green
            contrastText: '#EBEBEB', // White text for good contrast on green background
        },
        secondary: {
            light: '#a6d6a4',  // Lighter green
            main: '#21730A',   // New main theme color, softer green
            dark: '#4e9a4e',    // A darker shade of the secondary green
            contrastText: '#232427', // Black text for contrast
        },
        // background: {
        //     default: '#e8f0ff', // A very light blue, for a soft look
        //     paper: '#ccd8ff', // A slightly darker shade of light blue
        // },
    },
    typography: {
        h1: {
            '@media (max-width:600px)': {
                fontSize: '2rem',
            },
        },
        h2: {
            '@media (max-width:600px)': {
                fontSize: '1.75rem',
            },
        },
        h3: {
            '@media (max-width:600px)': {
                fontSize: '1.5rem',
            },
        },
        h4: {
            '@media (max-width:600px)': {
                fontSize: '1.5rem',
            },
        },
        h5: {
            '@media (max-width:600px)': {
                fontSize: '1.15rem',
            },
        },
        h6: {
            '@media (max-width:600px)': {
                fontSize: '1rem',
            },
        },
        body1: {
            '@media (max-width:600px)': {
                fontSize: '1rem',
            },
        },
        body2: {
            '@media (max-width:600px)': {
                fontSize: '0.7rem',
            },
        },
        subtitle1: {
            '@media (max-width:600px)': {
                fontSize: '1rem',
            },
        },
        subtitle2: {
            '@media (max-width:600px)': {
                fontSize: '0.875rem',
            },
        },
        button: {
            '@media (max-width:600px)': {
                fontSize: '0.75rem',
                lineHeight: '1.5rem'
            },
        },
        caption: {
            '@media (max-width:600px)': {
                fontSize: '0.75rem',
            },
        },
        overline: {
            '@media (max-width:600px)': {
                fontSize: '0.75rem',
            },
        },
    },
});

export default walletTheme;
