import httpClient from './httpClient';
import {ReferralData, User} from "../types";

export const fetchReferralDataApi = async (token: string) => {
    return httpClient.get<ReferralData>('/referral', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
};

export const fetchReferralSourceApi = async (token: string) => {
    return httpClient.get<ReferralData>(`/referral/source`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
};


export const uploadReferralIdApi = async (token: string, referralId: String) => {
    try {
        return httpClient.put<User>('/referral/id', {referralId}, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
    } catch (error) {
        console.error('Error uploading referral id:', error);
        throw error;
    }
};

export const uploadReferralPromoCodeApi = async (token: string, promoCode: String) => {
    try {
        return httpClient.put<User>('/referral/promo', {promoCode}, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
    } catch (error) {
        console.error('Error uploading referral promo code:', error);
        throw error;
    }
};


