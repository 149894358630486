import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import useNotification from "../../../useNotification";
import Typography from "@mui/material/Typography";
import {topUpApi} from "../../../api/cardsService";
import {useAuth0} from "@auth0/auth0-react";
import Grid from "@mui/material/Grid";
import {styled} from "@mui/material/styles";
import {fetchWalletBalanceDataApi} from "../../../api/walletsService";
import {FormControl, InputLabel, Select} from "@mui/material";
import {networkOptions} from "../../../constants/networkOptions";
import IconText from "../../_components/IconText";
import {currencyOptions} from "../../../constants/currencyOptions";
import axios from "axios";

interface TopUpDialogProps {
    open: boolean;
    onClose: () => void;
    cardId: string | undefined;
    cardNoLast4: string | undefined;
}

const DialogContentStyled = styled(DialogContent)(({theme}) => ({
    padding: theme.spacing(2),
}));

const ButtonStyled = styled(Button)(({theme}) => ({
    marginTop: theme.spacing(2),
}));

const TopUpDialog: React.FC<TopUpDialogProps> = ({open, onClose, cardId, cardNoLast4}) => {
    const {t} = useTranslation();
    const {getAccessTokenSilently} = useAuth0();
    const {notify} = useNotification();
    const [balance, setBalance] = useState<string>("-");
    const [amount, setAmount] = useState<string>("0.00");
    const [fee, setFee] = useState<string>("0.00");
    const [loading, setLoading] = useState<boolean>(false);
    const [lock, setLock] = useState<boolean>(false);
    const [activeNetwork, setActiveNetwork] = React.useState('');
    const [activeCurrency, setActiveCurrency] = useState<string>('');
    const menuItemIconSize = '1.6rem';

    useEffect(() => {
        if (activeNetwork?.length > 0 && activeCurrency?.length > 0) {
            checkWalletBalance(activeNetwork, activeCurrency);
        }
    }, [activeNetwork, activeCurrency]);

    const checkWalletBalance = async (network: string, currency: string) => {
        const token = await getAccessTokenSilently();
        if (token == null) {
            notify(t('authFailed'), "warning");
        } else {
            setLoading(true);
            fetchWalletBalanceDataApi(token, network, currency)
                .then((response) => {
                    setBalance(response.data.amount);
                    setFee(response.data.fee.toString());
                    notify("Balance updated", "info");
                    setLock(false);
                })
                .catch((err) => {
                    if (axios.isAxiosError(err)) {
                        setLock(true);
                        const errCode = err.response?.data.toString();
                        const errMsg = t(errCode) !== errCode ? t(errCode) : t("topUpFailed")
                        notify(errMsg, "warning");
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const handleConfirm = async () => {
        const amountNumber = parseFloat(amount);
        if (isNaN(amountNumber) || amountNumber <= 0) {
            notify(t('invalidAmount'), 'warning');
            return;
        }
        if (cardId == null) {
            notify(t('topUpFailed'), 'error');
            return;
        }
        setLoading(true);
        const token = await getAccessTokenSilently();
        if (token == null) {
            notify(t('authFailed'), "warning");
        } else {
            topUpApi(token, cardId, amountNumber * 100, activeNetwork, activeCurrency)
                .then(() => {
                    notify(t("topUpSuccess"), "success");
                    onClose();
                })
                .catch(
                    err => {
                        if (axios.isAxiosError(err)) {
                            const errCode = err.response?.data.toString();
                            const errMsg = t(errCode) !== errCode ? t(errCode) : t("topUpFailed")
                            notify(errMsg, "error");
                        }
                    }
                )
                .finally(() => setLoading(false));
        }
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="top-up-dialog-title" fullWidth maxWidth="sm">
            <DialogTitle id="top-up-dialog-title">{t("topUpCreditCard")}</DialogTitle>
            <DialogContentStyled>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>{t("cardNumber", {number: cardNoLast4})}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth margin='dense'>
                            <InputLabel id={"network-select-label"}>{t("network")}</InputLabel>
                            <Select
                                labelId={"network-select-label"}
                                label={t("network")}
                                value={activeNetwork}
                                onChange={(event) => setActiveNetwork(event.target.value)}
                                fullWidth
                            >
                                {
                                    networkOptions.map(nO => {
                                        return (
                                            <MenuItem key={'network-' + nO.value} value={nO.value}>
                                                <IconText text={t(nO.text)} alt={nO.alt}
                                                          src={nO.src}
                                                          iconSize={menuItemIconSize}/>
                                            </MenuItem>
                                        );
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth margin='dense'>
                            <InputLabel id={"currency-select-label"}>{t("currency")}</InputLabel>
                            <Select
                                labelId={"currency-select-label"}
                                label={t("currency")}
                                value={activeCurrency}
                                onChange={(event) => setActiveCurrency(event.target.value)}
                                fullWidth
                            >
                                {
                                    currencyOptions.map(nO =>
                                        nO.supportedNetwork.includes(activeNetwork ?? '') ?
                                            <MenuItem key={'network-' + nO.value} value={nO.value}>
                                                <IconText text={t(nO.text)} alt={nO.alt}
                                                          src={nO.src}
                                                          iconSize={menuItemIconSize}/>
                                            </MenuItem> : <></>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth margin={'dense'}>
                            <Typography>{t("walletBalance")}</Typography>
                        </FormControl>
                        <FormControl fullWidth sx={{textAlign: 'end'}}>
                            <Typography>{balance + ' ' + activeCurrency}</Typography>
                        </FormControl>
                        <FormControl fullWidth margin={'dense'}>
                            <Typography>{t("fee")}</Typography>
                        </FormControl>
                        <FormControl fullWidth sx={{textAlign: 'end'}}>
                            <Typography>{fee + ' ' + activeCurrency}</Typography>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            value={amount}
                            onChange={e => setAmount(e.target.value)}
                            fullWidth
                            margin="dense"
                            inputProps={{
                                pattern: "^\d*(\.\d{0,2})?$",
                                inputMode: "decimal",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonStyled
                            variant="contained"
                            color="primary"
                            onClick={handleConfirm}
                            disabled={loading || lock}
                            fullWidth
                        >
                            {loading ? t("loading") : t("confirm")}
                        </ButtonStyled>
                    </Grid>
                </Grid>
            </DialogContentStyled>
        </Dialog>
    );
};

export default TopUpDialog;
