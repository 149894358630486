import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {CreditCardPreview} from "../types";

interface CreditCardState {
    cards: CreditCardPreview[];
}

const initialState: CreditCardState = {
    cards: [],
};

export const creditCardSlice = createSlice({
    name: 'creditCard',
    initialState,
    reducers: {
        setCards: (state, action: PayloadAction<CreditCardPreview[]>) => {
            state.cards = action.payload;
        },
        addCard: (state, action: PayloadAction<CreditCardPreview>) => {
            state.cards.push(action.payload);
        },
        removeCard: (state, action: PayloadAction<string>) => {
            state.cards = state.cards.filter(card => card.id !== action.payload);
        },
        updateCard: (state, action: PayloadAction<CreditCardPreview>) => {
            const index = state.cards.findIndex(card => card.id === action.payload.id);
            if (index !== -1) {
                state.cards[index] = action.payload;
            }
        },
    },
});

export const {setCards, addCard, removeCard, updateCard} = creditCardSlice.actions;

export const selectCards = (state: RootState): CreditCardPreview[] => state.creditCard.cards;
export const selectCardByLastDigits = (value: string | undefined) => (state: RootState): CreditCardPreview | undefined => state.creditCard.cards.find((it) => it.cardNoLast4 === value);

export default creditCardSlice.reducer;
