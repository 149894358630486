import * as React from 'react';
import {useState} from 'react';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import KnowYourCustomerBlock from "./KnowYourCustomerBlock";
import StyledPaper from "./_components/StyledPaper";
import TabPanel from "./_components/TabPanel";
import SecurityBlock from './SecurityBlock';
import NotificationsBlock from "./NotificationsBlock";
import ApplicationBlock from "./ApplicationBlock";
import {useTranslation} from "react-i18next";
import Title from "../dashboard/Title";
import Container from "@mui/material/Container";

export default function Settings() {
    const {t} = useTranslation();
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <Container sx={{px:'0px!important'}}>
            <StyledPaper>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="settings tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab label="KYC" />
                    <Tab label={t("applicationPreferences")} />
                    {/*<Tab label="Security Settings" />*/}
                    {/*<Tab label="Notification Settings" />*/}
                </Tabs>

                <TabPanel value={tabValue} index={0}>
                    <Title>
                        {t("KYC")}
                    </Title>
                    {/*<Typography variant="h6" component="h2">KYC</Typography>*/}
                    <KnowYourCustomerBlock />
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                    <Title>
                        {t("applicationPreferences")}
                    </Title>
                    {/*<Typography variant="h6" component="h2">{t("applicationPreferences")}</Typography>*/}
                    <ApplicationBlock />
                </TabPanel>

                <TabPanel value={tabValue} index={2}>
                    <Title>
                        {t("securitySettings")}
                    </Title>
                    {/*<Typography variant="h6" component="h2">{t("securitySettings")}</Typography>*/}
                    <SecurityBlock />
                </TabPanel>

                <TabPanel value={tabValue} index={3}>
                    <Title>
                        {t("notificationSettings")}
                    </Title>
                    {/*<Typography variant="h6" component="h2">{t("notificationSettings")}</Typography>*/}
                    <NotificationsBlock />
                </TabPanel>
            </StyledPaper>
        </Container>
    );
}
