import * as React from 'react';
import {Box, Button, Card, Container, Grid, Typography} from '@mui/material';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
import {useTranslation} from 'react-i18next';
import previewCards from '../../assets/images/cards.png';
import {useAuth0} from "@auth0/auth0-react";

const item = {
    icon: <EdgesensorHighRoundedIcon/>,
    titleKey: 'features.item.title',
    descriptionKey1: 'features.item.description1',
    descriptionKey2: 'features.item.description2',
    imageLight: 'url("/static/images/templates/templates-images/mobile-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/mobile-dark.png")',
};

export default function Features() {
    const {t} = useTranslation();
    const {loginWithRedirect} = useAuth0();

    return (
        <Container id="features" sx={{py: {xs: 4, sm: 8}}}>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Box textAlign="center">
                        <Typography component="h2" variant="h4" color="primary.main">
                            {t('features.title')}
                        </Typography>
                        <Typography variant="body1" color="text.secondary" sx={{mb: {xs: 2, sm: 4}}}>
                            {t('features.subtitle')}
                        </Typography>
                        <Typography component="h2" variant="h5" color="primary.main" sx={{mt: {xs: 4, sm: 8}}}>
                            {t('features.whatWeProvide')}
                        </Typography>
                        <Typography variant="body1" color="text.secondary" sx={{mb: {xs: 2, sm: 4}}}>
                            {t('features.manageAssets')}
                        </Typography>
                    </Box>
                    <Card variant="outlined" sx={{mt: 4}}>
                        <Box
                            sx={{
                                backgroundImage: `url(${previewCards})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                minHeight: 450,
                            }}
                        />
                        <Box sx={{px: 3, py: 2}}>
                            <Typography color="primary.main" variant="h6" fontWeight="bold">
                                {t(item.titleKey)}
                            </Typography>
                            <Typography color="text.secondary" variant="body2" sx={{my: 1}}>
                                {t(item.descriptionKey1)}
                            </Typography>
                            <Typography color="text.secondary" variant="body2" sx={{my: 1}}>
                                {t(item.descriptionKey2)}
                            </Typography>
                            <Box sx={{textAlign: 'center', mt: 3}}>
                                {/*<Button variant="contained" color="primary" sx={{mx: 1}}>*/}
                                {/*    {t('features.learnMore')}*/}
                                {/*</Button>*/}
                                <Button variant="outlined" color="primary" sx={{mx: 1}}
                                        onClick={() => loginWithRedirect({
                                            appState: {
                                                returnTo: "/",
                                            },
                                            authorizationParams: {
                                                screen_hint: "login",
                                            },
                                        })}>
                                    {t('features.getStarted')}
                                </Button>
                            </Box>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}
