import * as React from 'react';
import {
    AppBar,
    Box,
    Button,
    Container,
    Drawer,
    IconButton,
    MenuItem,
    Toolbar,
    Typography
} from '@mui/material';
import {Menu as MenuIcon} from '@mui/icons-material';
import {useAuth0} from '@auth0/auth0-react';
import {useTheme} from '@mui/material/styles';
import {useTranslation} from "react-i18next";

const logoStyle = {
    width: 'auto',
    height: '50px',
    cursor: 'pointer',
};

function LandingPageTopBar() {
    const theme = useTheme();
    const {loginWithRedirect} = useAuth0();
    const {t, i18n} = useTranslation();
    const [open, setOpen] = React.useState(false);
    const english = 'en';
    const navigatorLanguage = navigator.language.toLowerCase();

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    const scrollToSection = (sectionId: string) => {
        const sectionElement = document.getElementById(sectionId);
        const offset = 128;
        if (sectionElement) {
            const targetScroll = sectionElement.offsetTop - offset;
            sectionElement.scrollIntoView({behavior: 'smooth'});
            window.scrollTo({
                top: targetScroll,
                behavior: 'smooth',
            });

            setOpen(false);
        }
    };

    const setNavigatorLanguage = () => {
        i18n.changeLanguage(navigatorLanguage)
    };

    const setEnglishLanguage = () => {
        i18n.changeLanguage(english);
    };

    const isNavigatorLanguageValidToDisplay = (): boolean => {
        const translationKey = `locales.${navigatorLanguage}`;
        const translationExists = t(translationKey) !== translationKey;
        return i18n.language.toLowerCase() !== navigatorLanguage && translationExists;
    }

    return (
        <AppBar
            position="fixed"
            sx={{
                boxShadow: 0,
                bgcolor: 'transparent',
                backgroundImage: 'none',
                mt: 2,
            }}
        >
            <Container maxWidth="lg">
                <Toolbar
                    variant="regular"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexShrink: 0,
                        borderRadius: '999px',
                        bgcolor: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
                        backdropFilter: 'blur(24px)',
                        maxHeight: 40,
                        border: '1px solid',
                        borderColor: 'divider',
                        boxShadow: theme.palette.mode === 'light' ?
                            '0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)' :
                            '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
                    }}
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                            alignItems: 'center',
                            px: 0,
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="primary"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                            sx={{ml: {xs: -1, md: -3}, mr: 1, display: {xs: 'flex', md: 'none'}}}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                            <Drawer anchor="left" open={open} disableScrollLock={true} onClose={toggleDrawer(false)}>
                                <Box
                                    sx={{
                                        width: 250,
                                        p: 2,
                                        backgroundColor: 'background.paper',
                                        flexGrow: 1,
                                        maxHeight:'calc(100vh - 24px - (8px * 4))'
                                    }}
                                    role="presentation"
                                    onClick={toggleDrawer(false)}
                                    onKeyDown={toggleDrawer(false)}
                                >
                                    <MenuItem onClick={() => scrollToSection('features')}>
                                        {t("features.menu")}
                                    </MenuItem>
                                    <MenuItem onClick={() => scrollToSection('faq')}>
                                        {t("faq.menu")}
                                    </MenuItem>
                                    {i18n.language.toLowerCase() !== english &&
                                        <MenuItem onClick={() => setEnglishLanguage()}>
                                            {t(`locales.${english}`)}
                                        </MenuItem>
                                    }
                                    {isNavigatorLanguageValidToDisplay() &&
                                        <MenuItem onClick={() => setNavigatorLanguage()}>
                                            {t(`locales.${navigatorLanguage}`)}
                                        </MenuItem>
                                    }
                                </Box>
                                <Box sx={{p: 2}}>
                                    {'support@lucrumwallet.net'}
                                </Box>
                            </Drawer>
                        </Box>
                        <img
                            src={require('../../assets/images/logo.png')}
                            style={logoStyle}
                            alt="logo"
                        />
                        <Box sx={{display: {xs: 'none', md: 'flex'}, ml: 2}}>
                            <MenuItem
                                onClick={() => scrollToSection('features')}
                                sx={{py: '6px', px: '12px'}}
                            >
                                <Typography variant="body2" color="text.primary">
                                    {t("features.menu")}
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                onClick={() => scrollToSection('faq')}
                                sx={{py: '6px', px: '12px'}}
                            >
                                <Typography variant="body2" color="text.primary">
                                    {t("faq.menu")}
                                </Typography>
                            </MenuItem>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: {xs: 'none', md: 'flex'},
                            gap: 1,
                            alignItems: 'center',
                        }}
                    >
                        {i18n.language.toLowerCase() !== english &&
                            <Button
                                color="primary"
                                variant="text"
                                size="small"
                                onClick={() => setEnglishLanguage()}
                            >
                                {t("locales.en")}
                            </Button>
                        }
                        {isNavigatorLanguageValidToDisplay() &&
                            <Button
                                color="primary"
                                variant="text"
                                size="small"
                                onClick={() => setNavigatorLanguage()}>
                                {t(`locales.${navigator.language}`)}
                            </Button>
                        }
                    </Box>
                    <Box
                        sx={{
                            gap: 1,
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            color="primary"
                            variant="text"
                            size="small"
                            onClick={() => loginWithRedirect({
                                appState: {
                                    returnTo: "/",
                                },
                                authorizationParams: {
                                    screen_hint: "login",
                                },
                            })}
                        >
                            {t("signIn")}
                        </Button>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default LandingPageTopBar;
