import httpClient from './httpClient';
import {KycDetailsForm, KycDetailsRequest, User} from "../types";

export const fetchUserDataApi = async (token: string) => {
    return httpClient.get<User>('/users/me', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
};

export const fetchKycStatusApi = async (token: string) => {
    return httpClient.get<User>('/users/check-kyc', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
};

export const uploadKycApi = async (token: string, files: File[], kycDetails: KycDetailsForm) => {
    try {
        const formData = new FormData();
        files.forEach((file, index) => {
            formData.append('files', file);
        });
        formData.append('kycRequest', new Blob([JSON.stringify(mapKycDetailsFormToRequest(kycDetails))], {type: 'application/json'}));

        return httpClient.post<User>('/users/upload-kyc', formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
    } catch (error) {
        console.error('Error uploading KYC details:', error);
        throw error;
    }
};

function mapKycDetailsFormToRequest(form: KycDetailsForm): KycDetailsRequest {
    const dateToTimestamp = (dateStr: string): number => new Date(dateStr).getTime();

    return {
        firstName: form.firstName,
        lastName: form.lastName,
        dateOfBirth: dateToTimestamp(form.dateOfBirth),
        occupation: form.occupation,
        annualIncome: form.annualIncome,
        documentType: form.documentType,
        documentNumber: form.documentNumber,
        documentCountry: form.documentCountry,
        documentExpiryDate: dateToTimestamp(form.documentExpiryDate),
        addressDetails: form.addressDetails,
        addressCity: form.addressCity,
        addressCountry: form.addressCountry,
        addressPostCode: form.addressPostCode,
        nickName: form.nickName,
        contactPhone: form.contactPhone
    };
}

