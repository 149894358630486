import {
    CardApplyStatus,
    CardLevel,
    CardStatus,
    CryptoNetworks,
    KycStatus,
    CardTransactionType,
    CryptoTransactionType
} from "../types";

const mapEnum = <T extends { [key: string]: string | number }>(enumType: T, value: string | undefined): T[keyof T] => {
    if (value != null && Object.values(enumType).includes(value.toUpperCase())) {
        return value as T[keyof T];
    }
    return enumType.UNDEFINED as T[keyof T];
};

export const mapKycStatus = (num: string | undefined): KycStatus => {
    return mapEnum(KycStatus, num);
};

export const mapCardStatus = (num: string | undefined): CardStatus => {
    return mapEnum(CardStatus, num);
};

export const mapCardApplyStatus = (num: string | undefined): CardApplyStatus => {
    return mapEnum(CardApplyStatus, num);
};

export const mapCardLevel = (num: string | undefined): CardLevel => {
    return mapEnum(CardLevel, num);
};

export const mapCryptoNetwork = (num: string | undefined): CryptoNetworks => {
    return mapEnum(CryptoNetworks, num);
};

export const mapCardTransactionType = (num: string | undefined): CardTransactionType => {
    return mapEnum(CardTransactionType, num);
};

export const mapCryptoTransactionType = (num: string | undefined): CryptoTransactionType => {
    return mapEnum(CryptoTransactionType, num);
};
