import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useTheme} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';

export default function FAQ() {
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const theme = useTheme();
    const {t} = useTranslation();

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Container
            id="faq"
            sx={{
                pt: {xs: 4, sm: 8},
                pb: {xs: 6, sm: 12},
                px: {xs: 2, sm: 4},
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.primary,
            }}
        >
            <Typography
                component="h2"
                variant="h4"
                color="primary.main"
                sx={{
                    mb: {xs: 4, sm: 6},
                    width: {xs: '100%', md: '60%'},
                    textAlign: {xs: 'center', sm: 'left', md: 'center'},
                }}
            >
                {t('faq.title')}
            </Typography>
            <Box sx={{width: '100%'}}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{color: theme.palette.primary.main}}/>}
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        sx={{backgroundColor: theme.palette.background.paper}}
                    >
                        <Typography component="h3" variant="subtitle1" color="primary.dark">
                            {t('faq.questions.whatIsLucrumWallet')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{backgroundColor: theme.palette.background.paper}}>
                        <Typography variant="body1" gutterBottom>
                            {t('faq.answers.whatIsLucrumWallet')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{color: theme.palette.primary.main}}/>}
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                        sx={{backgroundColor: theme.palette.background.paper}}
                    >
                        <Typography component="h3" variant="subtitle1" color="primary.dark">
                            {t('faq.questions.whoIssuesTheCards')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{backgroundColor: theme.palette.background.paper}}>
                        <Typography variant="body1" gutterBottom>
                            {t('faq.answers.whoIssuesTheCards')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{color: theme.palette.primary.main}}/>}
                        aria-controls="panel3d-content"
                        id="panel3d-header"
                        sx={{backgroundColor: theme.palette.background.paper}}
                    >
                        <Typography component="h3" variant="subtitle1" color="primary.dark">
                            {t('faq.questions.ensureSafety')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{backgroundColor: theme.palette.background.paper}}>
                        <Typography variant="body1" gutterBottom>
                            {t('faq.answers.ensureSafety')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{color: theme.palette.primary.main}}/>}
                        aria-controls="panel4d-content"
                        id="panel4d-header"
                        sx={{backgroundColor: theme.palette.background.paper}}
                    >
                        <Typography component="h3" variant="subtitle1" color="primary.dark">
                            {t('faq.questions.applyForCard')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{backgroundColor: theme.palette.background.paper}}>
                        <Typography variant="body1" gutterBottom>
                            {t('faq.answers.applyForCard')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{color: theme.palette.primary.main}}/>}
                        aria-controls="panel5d-content"
                        id="panel5d-header"
                        sx={{backgroundColor: theme.palette.background.paper}}
                    >
                        <Typography component="h3" variant="subtitle1" color="primary.dark">
                            {t('faq.questions.activateCard')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{backgroundColor: theme.palette.background.paper}}>
                        <Typography variant="body1" gutterBottom>
                            {t('faq.answers.activateCard')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{color: theme.palette.primary.main}}/>}
                        aria-controls="panel6d-content"
                        id="panel6d-header"
                        sx={{backgroundColor: theme.palette.background.paper}}
                    >
                        <Typography component="h3" variant="subtitle1" color="primary.dark">
                            {t('faq.questions.fees')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{backgroundColor: theme.palette.background.paper}}>
                        <Typography variant="body1" gutterBottom>
                            {t('faq.answers.fees')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{color: theme.palette.primary.main}}/>}
                        aria-controls="panel7d-content"
                        id="panel7d-header"
                        sx={{backgroundColor: theme.palette.background.paper}}
                    >
                        <Typography component="h3" variant="subtitle1" color="primary.dark">
                            {t('faq.questions.supportedCryptocurrencies')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{backgroundColor: theme.palette.background.paper}}>
                        <Typography variant="body1" gutterBottom>
                            {t('faq.answers.supportedCryptocurrencies')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Container>
    );
}
