import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../dashboard/Title';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import {CardTransaction, CreditCardDetails, CardTransactionType} from "../../types";
import {useTranslation} from "react-i18next";
import {mapCryptoNetwork, mapCardTransactionType} from "../../utils/transformations";
import {getPrintDollarAmount} from "../../utils/moneyUtils";

interface TransactionRow {
    id: string;
    date: string;
    description: string;
    transactionId: string;
    printUsdAmount: string;
}

export interface OrdersProps {
    cardDetails: CreditCardDetails;
    cardTransactions: CardTransaction[] | null;
}

export default function CardTransactionsBlock({cardDetails, cardTransactions}: OrdersProps) {
    const {t} = useTranslation();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const getFormattedTransactionDescription = (transaction: CardTransaction) => {
        let descriptionFirstWord = '';
        switch (mapCardTransactionType(transaction.type)) {
            case CardTransactionType.CHARGE:
                descriptionFirstWord = t('sent');
                break;
            case CardTransactionType.TOP_UP:
                descriptionFirstWord = t('received');
                break;
            case CardTransactionType.FEE:
                descriptionFirstWord = t('fee');
                break;
            case CardTransactionType.REFUND:
                descriptionFirstWord = t('refund');
                break;
            default:
                break;
        }
        return descriptionFirstWord + t(mapCryptoNetwork(transaction.paymentCurrency));
    }

    const getPreparedTransactionRows = (): TransactionRow[] => {
        const rows: TransactionRow[] = [];

        cardTransactions?.forEach((transaction) => {
            const id = transaction.createdAt.toString();
            const today = new Date();
            const creationDate = new Date(transaction.createdAt);
            const date = today.getDate() === creationDate.getDate() ?
                creationDate.toLocaleTimeString() : creationDate.toLocaleDateString()
            const description = getFormattedTransactionDescription(transaction);
            const transactionId = `${transaction.currency ?? ""} ${cardDetails?.cardNoLast4 ?? ""}`;
            const printUsdAmount = getPrintDollarAmount(Number(transaction.amount));

            rows.push({
                id: id,
                date: date,
                description: description,
                transactionId: transactionId,
                printUsdAmount: printUsdAmount,
            });
        });

        return rows;
    }

    if (cardTransactions == null) {
        return <></>;
    }

    return (
        <React.Fragment>
            <Title>{t('recentTransactions')}</Title>
            <Box sx={{ overflowX: 'auto' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ padding: 1 }}>{t('date')}</TableCell>
                            <TableCell sx={{ padding: 1 }}>{t('description')}</TableCell>
                            {!isTablet && <TableCell sx={{ padding: 1 }}>{t('transactionId')}</TableCell>}
                            <TableCell sx={{ padding: 1 }} align="right">{t('amountUSD')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getPreparedTransactionRows().map((row) => (
                            <TableRow key={row.id}>
                                <TableCell sx={{ padding: 1 }}>{row.date}</TableCell>
                                <TableCell sx={{ padding: 1 }}>{row.description}</TableCell>
                                {!isTablet && <TableCell sx={{ padding: 1 }}>{row.transactionId}</TableCell>}
                                <TableCell sx={{ padding: 1 }} align="right">{row.printUsdAmount}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </React.Fragment>
    );
}
