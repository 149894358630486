import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useTranslation} from "react-i18next";

export default function ProgressLoader() {
    const {t} = useTranslation();

    return (
        <Container maxWidth="md">
            <Box mt={4} mb={4} display="flex" flexDirection="column" alignItems="center">
                <CircularProgress/>
                <Typography variant="h6" align="center" style={{marginTop: 16}}>
                    {t("loading")}
                </Typography>
            </Box>
        </Container>
    );
}
