import * as React from 'react';
import StyledPaper from "../settings/_components/StyledPaper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../settings/_components/TabPanel";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import CryptoTransactionsBlock from "./CryptoTransactionsBlock";
import {useAuth0} from "@auth0/auth0-react";
import useNotification from "../../useNotification";
import {CardTransaction, CreditCardDetails, CryptoTransaction} from "../../types";
import {useSelector} from "react-redux";
import {selectCards} from "../../redux/cards";
import {fetchCardDataApi, fetchCardTransactionsApi} from "../../api/cardsService";
import ProgressLoader from "../_components/ProgressLoader";
import {fetchCryptoTransactionsApi} from "../../api/cryptoTransactionsService";
import CardTransactionsBlock from "./CardTransactionsBlock";
import Container from "@mui/material/Container";

export default function Transactions() {
    const [tabValue, setTabValue] = useState(0);
    const {t} = useTranslation();
    const {getAccessTokenSilently} = useAuth0();
    const {notify} = useNotification();
    const [cardDetails, setCardDetails] = useState<CreditCardDetails>();
    const [cryptoTransaction, setCryptoTransaction] = useState<CryptoTransaction[]>([]);
    const [cardTransactions, setCardTransactions] = useState<CardTransaction[]>([]);
    const creditCardPreviewArray = useSelector(selectCards);
    const [cardLoading, setCardLoading] = useState(true);
    const [cryptoTransactionsLoading, setCryptoTransactionsLoading] = useState(true);
    const [cardTransactionsLoading, setCardTransactionsLoading] = useState(true);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const retrieveCardInfo = async (cardId: string) => {
        const token = await getAccessTokenSilently();

        fetchCardDataApi(token, cardId)
            .then(
                response => {
                    setCardDetails(response.data);
                }
            )
            .catch(
                () => {
                    notify(t('retrieveCardError'), 'error');
                }
            )
            .finally(
                () => {
                    setCardLoading(false)
                }
            );
    }

    const retrieveCryptoTransactions = async () => {
        const token = await getAccessTokenSilently();

        fetchCryptoTransactionsApi(token)
            .then(
                response => {
                    setCryptoTransaction(response.data);
                }
            )
            .catch(
                () => {
                    notify(t('retrieveTransactionsError'), 'error');
                }
            )
            .finally(
                () => {
                    setCryptoTransactionsLoading(false)
                }
            );
    }

    const retrieveCardTransactions = async (cardId: string) => {
        const token = await getAccessTokenSilently();

        fetchCardTransactionsApi(token, cardId)
            .then(
                response => {
                    setCardTransactions(response.data);
                }
            )
            .catch(
                () => {
                    notify(t('retrieveTransactionsError'), 'error');
                }
            )
            .finally(
                () => {
                    setCardTransactionsLoading(false)
                }
            );
    }

    useEffect(() => {
        if (creditCardPreviewArray.length > 0) {
            const cardId = creditCardPreviewArray[0].id;
            retrieveCardInfo(cardId);
            retrieveCryptoTransactions();
            retrieveCardTransactions(cardId);
        } else {
            setCardLoading(false);
            setCryptoTransactionsLoading(false);
        }
    }, [creditCardPreviewArray]);


    return (
        <Container sx={{px:'0px!important'}}>
            <StyledPaper>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="settings tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab label={t('cryptoTransactions')}/>
                    {!cardDetails ? <></> :
                        <Tab label={t('cardTransactions')}/>
                    }
                </Tabs>

                <TabPanel value={tabValue} index={0}>
                    {
                        cardLoading || cryptoTransactionsLoading ? <ProgressLoader/> :
                            <CryptoTransactionsBlock cardDetails={cardDetails} cryptoTransactions={cryptoTransaction}/>

                    }
                </TabPanel>
                {!cardDetails ? <></> :
                    <TabPanel value={tabValue} index={1}>
                        {
                            cardLoading || cardTransactionsLoading ?
                                <ProgressLoader/> :
                                <CardTransactionsBlock cardDetails={cardDetails} cardTransactions={cardTransactions}/>
                        }
                    </TabPanel>
                }

            </StyledPaper>
        </Container>
    );
}
