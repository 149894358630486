import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import SaveButton from "./_components/SaveButton";
import Section from "./_components/Section";
import * as React from "react";

function NotificationsBlock() {
    return (<Section container spacing={2}>
        <Grid item xs={12}>
            <FormControlLabel
                control={<Switch/>}
                label="Email Notifications"
            />
        </Grid>
        <Grid item xs={12}>
            <FormControlLabel
                control={<Switch/>}
                label="SMS Notifications"
            />
        </Grid>
        <Grid item xs={12}>
            <FormControlLabel
                control={<Switch/>}
                label="Push Notifications"
            />
        </Grid>
        <Grid item xs={12}>
            <SaveButton variant="contained" color="primary">
                Save Notification Settings
            </SaveButton>
        </Grid>
    </Section>)
}

export default NotificationsBlock;
