import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    // margin: 'auto',
    // maxWidth: 600,
    [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        padding: theme.spacing(1),
    },
}));

export default StyledPaper;
