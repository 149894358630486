import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import {CreditCardDetails, CryptoTransaction, CryptoTransactionType} from "../../types";
import {useTranslation} from "react-i18next";
import {mapCryptoNetwork, mapCryptoTransactionType} from "../../utils/transformations";
import {getPrintDollarAmount} from "../../utils/moneyUtils";

interface TransactionRow {
    id: string;
    date: string;
    description: string;
    transactionId: string;
    printUsdAmount: string;
}

export interface OrdersProps {
    cardDetails: CreditCardDetails | undefined;
    cryptoTransactions: CryptoTransaction[];
}

export default function CryptoTransactionsBlock({cardDetails, cryptoTransactions}: OrdersProps) {
    const {t} = useTranslation();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const getFormattedTransactionDescription = (transaction: CryptoTransaction) => {
        let descriptionFirstWord = '';
        switch (mapCryptoTransactionType(transaction.type)) {
            case CryptoTransactionType.INCOMING_TRANSFER:
                descriptionFirstWord = t('received');
                break;
            case CryptoTransactionType.OUTGOING_TRANSFER:
                descriptionFirstWord = t('sent');
                break;
            case CryptoTransactionType.TOP_UP:
                descriptionFirstWord = t('topUp');
                break;
            case CryptoTransactionType.CARD_PURCHASE:
                descriptionFirstWord = t('cardPurchase');
                break;
            default:
                break;
        }
        return descriptionFirstWord + ' ' + t(mapCryptoNetwork(transaction.network));
    }

    const isCardCryptoTransaction = (transaction: CryptoTransaction) => {
        switch (mapCryptoTransactionType(transaction.type)) {
            case CryptoTransactionType.INCOMING_TRANSFER:
            case CryptoTransactionType.OUTGOING_TRANSFER:
                return false;
            default:
                return true;
        }
    }

    const getPreparedCryptoTransactionRows = (): TransactionRow[] => {
        const rows: TransactionRow[] = [];

        cryptoTransactions?.forEach((transaction) => {
            const id = new Date(transaction.creationTimestamp).getTime().toString();
            const today = new Date();
            const creationDate = new Date(transaction.creationTimestamp);
            const date = today.getDate() === creationDate.getDate() ?
                creationDate.toLocaleTimeString() : creationDate.toLocaleDateString()
            const description = getFormattedTransactionDescription(transaction);
            const network = isCardCryptoTransaction(transaction) ?
                mapCryptoNetwork(transaction.network) : cardDetails?.cardNoLast4 ?? "";
            const transactionId = `${transaction.currency} ${network}`;
            const printUsdAmount = getPrintDollarAmount(Number(transaction.amount));

            rows.push({
                id: id,
                date: date,
                description: description,
                transactionId: transactionId,
                printUsdAmount: printUsdAmount,
            });
        });

        return rows;
    }

    return (
        <React.Fragment>
            <Box sx={{overflowX: 'auto'}}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ padding: 1 }}>{t('date')}</TableCell>
                            <TableCell sx={{ padding: 1 }}>{t('description')}</TableCell>
                            {!isTablet && <TableCell sx={{ padding: 1 }}>{t('transactionId')}</TableCell>}
                            <TableCell sx={{ padding: 1 }} align="right">{t('amountUSD')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getPreparedCryptoTransactionRows().map((row) => (
                            <TableRow key={row.id}>
                                <TableCell sx={{ padding: 1 }}>{row.date}</TableCell>
                                <TableCell sx={{ padding: 1 }}>{row.description}</TableCell>
                                {!isTablet && <TableCell sx={{ padding: 1 }}>{row.transactionId}</TableCell>}
                                <TableCell sx={{ padding: 1 }} align="right">{row.printUsdAmount}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </React.Fragment>
    );
}
