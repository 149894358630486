import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PaymentIcon from '@mui/icons-material/Payment';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SecurityIcon from '@mui/icons-material/Security';
import UpdateIcon from '@mui/icons-material/Update';
import {useTheme} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';

const items = [
    {icon: <PaymentIcon/>, titleKey: 'highlights.item1.title', descriptionKey: 'highlights.item1.description'},
    {icon: <LocalMallIcon/>, titleKey: 'highlights.item2.title', descriptionKey: 'highlights.item2.description'},
    {icon: <SyncAltIcon/>, titleKey: 'highlights.item3.title', descriptionKey: 'highlights.item3.description'},
    {icon: <AttachMoneyIcon/>, titleKey: 'highlights.item4.title', descriptionKey: 'highlights.item4.description'},
    {icon: <SecurityIcon/>, titleKey: 'highlights.item5.title', descriptionKey: 'highlights.item5.description'},
    {icon: <UpdateIcon/>, titleKey: 'highlights.item6.title', descriptionKey: 'highlights.item6.description'},
];

export default function Highlights() {
    const theme = useTheme();
    const {t} = useTranslation();

    return (
        <Box
            id="highlights"
            sx={{
                pt: {xs: 4, sm: 12},
                pb: {xs: 8, sm: 16},
                backgroundColor: theme.palette.background.default,
            }}
        >
            <Container
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: {xs: 3, sm: 6},
                    px: {xs: 2, sm: 3},
                }}
            >
                <Box
                    sx={{
                        width: {xs: '100%', md: '60%'},
                        textAlign: {xs: 'center', md: 'center'},
                    }}
                >
                    <Typography component="h2" variant="h4" color="primary.main" gutterBottom>
                        {t('highlights.sectionTitle')}
                    </Typography>
                    <Typography variant="body1" sx={{color: theme.palette.grey[400]}}>
                        {t('highlights.sectionDescription')}
                    </Typography>
                </Box>
                <Grid container spacing={2.5}>
                    {items.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card
                                sx={{
                                    p: 3,
                                    height: '100%',
                                    border: `1px solid ${theme.palette.primary.main}`,
                                    backgroundColor: theme.palette.background.paper,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                <Box sx={{color: theme.palette.primary.main, opacity: '50%', mb: 2}}>
                                    {item.icon}
                                </Box>
                                <Typography fontWeight="medium" gutterBottom>
                                    {t(item.titleKey)}
                                </Typography>
                                <Typography variant="body2" sx={{color: theme.palette.grey[400]}}>
                                    {t(item.descriptionKey)}
                                </Typography>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}
