import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface TokenLogo {
    token: string,
    showNetwork: boolean,
    network?: string
}

const logos: TokenLogo[] = [
    {
        token: require('../../assets/images/tether.png'),
        showNetwork: true,
        network: require('../../assets/images/arb.png')
    },
    {
        token: require('../../assets/images/tether.png'),
        showNetwork: true,
        network: require('../../assets/images/bnb.png')
    },
    {
        token: require('../../assets/images/tether.png'),
        showNetwork: true,
        network: require('../../assets/images/eth.png')
    },
    {
        token: require('../../assets/images/tether.png'),
        showNetwork: true,
        network: require('../../assets/images/trc.png')
    },
    {
        token: require('../../assets/images/eth.png'),
        showNetwork: false
    }
];

const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 2,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        }
    ]
};

export default function SupportedTokens() {
    return (
        <Box id="logoCollection" sx={{py: 4}}>
            <Slider {...sliderSettings}>
                {logos.map((logo, index) => (
                    <Box key={index} sx={{position: 'relative', textAlign: 'center', maxWidth: '80px'}}>
                        <Box
                            component="img"
                            src={logo.token}
                            alt={`Token ${index + 1}`}
                            sx={{
                                width: '80px',
                                height: '80px',
                                margin: '0 auto',
                                opacity: 0.7,
                            }}
                        />
                        {logo.showNetwork && (
                            <Box
                                component="img"
                                src={logo.network}
                                alt={`Network for token ${index + 1}`}
                                sx={{
                                    position: 'absolute',
                                    width: '35px',
                                    height: '35px',
                                    bottom: '2px',
                                    right: '2px',
                                    backgroundColor: 'white', // Ensures visibility
                                    borderRadius: '50%', // Optional for rounded icons
                                    padding: '2px'
                                }}
                            />
                        )}
                    </Box>
                ))}
            </Slider>
        </Box>
    );
}
