import React from 'react';
import {Routes, Route} from 'react-router-dom';
import Dashboard from "./components/dashboard/Dashboard";
import LandingPage from "./pages/LandingPage";
import InternalPage from "./pages/InternalPage";
import Cards from "./components/cards/Cards";
import Wallets from "./components/wallets/Wallets";
import Transactions from "./components/transactions/Transactions";
import Settings from "./components/settings/Settings";
import CardDetails from "./components/card-details/CardDetails";
import Referral from "./components/referral/Referral";

function AppRoutes() {
    return (
        <Routes>
            <Route path="/welcome" element={<LandingPage/>}/>
            <Route path="/" element={<InternalPage/>}>
                <Route path="" element={<Dashboard/>}/>
                <Route path="cards" element={<Cards/>}/>
                <Route path="card/:last4digits" element={<CardDetails/>}/>
                <Route path="wallets" element={<Wallets/>}/>
                <Route path="transactions" element={<Transactions/>}/>
                <Route path="referral" element={<Referral/>}/>
                <Route path="settings" element={<Settings/>}/>
            </Route>
        </Routes>
    );
}

export default AppRoutes;
